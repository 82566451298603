import React, { useState, useContext, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import themeSignUpIn from "../themeSignUpIn";
import ReactGA from "react-ga4";

var snackBar = null;

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  whiteText: {
    color: "#ffffff",
  },
  whiteButton: {
    color: "#ffffff",
    margin: theme.spacing(1, 0, 3),
  },
}));

export default function Homepage(props) {
  snackBar = props.enqueueSnackbar;
  ReactGA.send({hitType: "pageview",page: "/homepage", title:"Homepage"});

  const history = useHistory();
  const classes = useStyles();

  return (
    <ThemeProvider theme={themeSignUpIn}>
      <Container
        component="main"
        maxWidth="xs"
        className="container"
        style={{
          background: `linear-gradient(rgba(00,199,172,1),transparent)`,
          backgroundColor: "#2d2d68",
          maxHeight: "window.innerHeight",
        }}
      >
        <Grid
          container
          alignItems="left"
          style={{ height: "90vh", overflow: "auto", marginTop: "10vh" }}
        >
          <Grid item xs={12} align="left">
            <Typography
              variant="h4"
              color="textPrimary"
              align="center"
              style={{ marginTop: "3vh" }}
            >
              MyWay Homepage
            </Typography>
            <Typography
              variant="h6"
              color="textPrimary"
              align="center"
              style={{ marginTop: "3vh" }}
            >
              MyWay aims to help women students feel safer walking home on their own at night.{" "}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              You can use the MyWay app to see up to date info on:
              <ul>
                <li>How much people movement there is on streets in Reading town centre and around the university area.</li>
                <li>Which streets are better lit at night.</li>
                <li>Any recent incidents (such as fighting or dodgy men approaching women) reported by other users of MyWay</li>
              </ul>
              You can add friends/housemates so you can see each other's location (unless you're on a secret mission!),
              and you can even set it up to automatically text your Mum when you've got home safely.<br/>
              You can also use MyWay to show you directions home from wherever you are at the press of a button.
              <br/><br/>
              You need to create a free user account to use the app.  You can either use your email address and create a password,
              or you can simply sign-up with Google.
            </Typography>
            <Typography
              variant="h6"
              color="textPrimary"
              align="center"
              style={{ marginTop: "3vh" }}
            >
              How we use your data{" "}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              You can read our full Privacy Policy using the button below.  
              Rest assured we do not sell or share your data with any other organisation.
              Here is a summary of how we use your data:
              <ul>
                <li>
                  We use your name and contact details so we can contact you for
                  feedback on the MyWay app and provide support and maintenance.
                </li>
                <li>
                  We use your “home address” (if you have set it in the app) so
                  we can show you the route home at the push of a button, and
                  automatically notify those Friends you choose to
                  notify when you complete your journey home.
                </li>
                <li>
                  We use the user ID of users you identified as Friends in the
                  MyWay app so you can see them (if the Friend allows) and the
                  Friend can see your whereabouts (if you let them) on the app.
                </li>
                <li>
                  We use your phone’s GPS coordinates so we can show you and
                  your Friends (if you share your location in the app) where you
                  are on the map.
                </li>
                <li>If you created your user account using the "Sign up with Google" button, 
                  then we store the email address associated with your Google account so you can "Login with Google" in future.
                  Use of information received from Google APIs will adhere to the 
                  <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">Google API Services User Data Policy</a>, 
                  including the Limited Use requirements.</li>
              </ul>
            </Typography>

            <Button
              fullWidth
              href="/privacy_policy"
              variant="outlined"
              color="primary"
              className={classes.whiteButton}
            >
              Privacy Policy
            </Button>
            <Button
              fullWidth
              href="/signup"
              variant="outlined"
              color="primary"
              className={classes.whiteButton}
            >
              Back to Sign Up
            </Button>
            <Button
              fullWidth
              href="/signin"
              variant="outlined"
              color="primary"
              className={classes.whiteButton}
            >
              Back to Sign In
            </Button>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
