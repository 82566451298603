import * as moment from "moment";

//function to format the POST payload to x-www-url-encoded
export function formatPayload(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export function ParseBool(value) {
    if (typeof(value) === 'string'){
        value = value.trim().toLowerCase();
    };
    switch(value){
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
            return true;
        default: 
            return false;
    }
};

export  function howLongAgo(timestamp) {
    var currentTime=new Date();
    var minsAgo=moment(currentTime).diff(timestamp,'minutes');
        
    if(minsAgo<=2) {
      return ("just now");
    }
    if (minsAgo <= 5) {
      return "a few mins ago";
    }
    if (minsAgo >= 30 && minsAgo <= 35) {
      return "half an hour ago";
    }
    if (minsAgo >= 55 && minsAgo < 65) {
      return "an hour ago";
    }
    if (minsAgo < 60) {
      return minsAgo + " mins ago";
    }

    if(minsAgo>=65 && minsAgo<720) {
      return (Math.floor(minsAgo/60)+"hrs "+ minsAgo % 60 + " mins ago");
    }
    return moment(timestamp).format("D MMM HH:mm");
  }

export function isLoggedIn(login) {
    if(login){
        console.log("Login True");
        return true;
    }
    else{
        if (document.cookie.includes("token")) {
          console.log("Cookie Found");
          return true;
        }
        else {
            console.log("Not Logged In");
            return false;
        }
    }    
};

export default isLoggedIn;
  