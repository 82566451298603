import React, { useState, useEffect } from "react";
import {
  RetrieveFeedback,
  SubmitFeedback,
  RemoveFeedback,
  } from "../data_exchange/Feedback_Data";
import NewFeedback from "../ui_elements/NewFeedback";
import exit_icon from "../../media/MyWay__close_icon.svg";
import { Button, 
  Card, 
  CardContent, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Grid,
  IconButton,
  Typography } from "@mui/material";
import * as moment from "moment";

const bigButtonStyle = {
    background: "#00c7ac",
    color: "white",
  };
  
export default function ShowFeedback(props) {
  const [myFeedback, setMyFeedback] = useState([]);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    feedbackIndex: 0,
    showNewFeedback: false,
  });

  useEffect(() => {
    loadMyFeedback();
  }, []);

  const loadMyFeedback = async () => {
    var fetchFeedback = [];
    fetchFeedback = await RetrieveFeedback();
    //prevent errors if unretrieved
    if (fetchFeedback !== null) {
      //map the returned array to the friend context
      var arrayOfFeedback = fetchFeedback.map(
        (feedback) =>
          (feedback = {
            feedbackID: feedback.feedbackid,
            description: feedback.description,
            status: feedback.status,
            timestamp: feedback.timestamp,
            lastUpdated: new Date().toUTCString(),
            clickable: true,
            type: "feedback",
          })
      );
      setMyFeedback(arrayOfFeedback);
    } else {
      // There is no feedback to see
      setMyFeedback([]);
      hideFeedback();
    }
  };

  //removes feedback from the context
  async function removeFeedback(index) {
    await RemoveFeedback(myFeedback[index].feedbackID);
    let newFeedback = myFeedback.filter((item) => item !== myFeedback[index]);
    setMyFeedback(newFeedback);
  }

  function showNewFeedback(index) {
    setState({ ...state, feedbackIndex: index, showNewFeedback: true });
  }

  const hideFeedback = () => {
    setOpen(false);
    props.parentCallback(myFeedback.length);
  };

  const hideNewFeedback = () => {
    setState({ ...state, feedbackIndex: 0, showNewFeedback: false });
    loadMyFeedback();
  };

  return (
    <Dialog
      aria-labelledby="my-feedback-dialog"
      aria-describedby="lists-my-feedback-and-lets-user-add-new-feedback"
      open={props.open}
      onClose={hideFeedback}
      scroll="paper"
      closeAfterTransition
    >
      <DialogTitle id="dialog-title">
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={11} style={{ textAlign: "center" }}>
            <Typography
              variant="h6"
              color="textPrimary"
              style={{ textAlign: "center" }}
            >
              My feedback
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              edge="start"
              color="primary"
              style={{ position: "relative", top: -2, right: 0 }}
              onClick={hideFeedback}
              aria-label="close"
            >
              <img
                src={exit_icon}
                color="primary"
                width="20rem"
                height="20rem"
              ></img>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {/* Returns empty list if no feedback found :-) */}
        {myFeedback.length === 0 ? (
          <Card style={{ margin: 3 }}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    component="p"
                    color="textPrimary"
                    display="inline"
                  >
                    Your feedback will appear here. We would love to hear from you.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ) : (
          myFeedback.map((feedback, i) => {
            return (
              <Card style={{ margin: 3 }}>
                <CardContent>
                  <Grid container spacing={1}>
                  <Grid item xs={9}>
                      <Typography
                        variant="body1"
                        component="p"
                        color="textPrimary"
                        display="inline"
                      >
                        {feedback.description}<br/>
                        Sumbitted at: 
                        {moment(feedback.timestamp).format(
                          " HH:mm    D MMM YYYY"
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        component="p"
                        color="textSecondary"
                        display="inline"
                      >
                        Status: {feedback.status}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={2}>
                      <Button
                        size="small"
                        color="secondary"
                        onClick={() => removeFeedback(i)}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          })
        )}
        {myFeedback !== null ? (
          <NewFeedback
            open={state.showNewFeedback}
            feedback={myFeedback[state.feedbackIndex]}
            parentCallback={hideNewFeedback}
          />
        ) : null}
        <Grid container spacing={2}>
          <Grid item xs={12}>
              <Button
                  variant="outlined"
                  style={bigButtonStyle}
                  onClick={showNewFeedback}
                  fullWidth
              >
                  Give Feedback
              </Button>
          </Grid>
        </Grid>  
      </DialogContent>
    </Dialog>
  );
}
