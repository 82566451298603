import { withStyles} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";


const AntSwitch = withStyles(theme => ({
    root: {
      width: 40,
      height: 24,
      padding: 2,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.white,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: "#00c7ac",
          borderColor: "#00c7ac",
        },
      },
    },
    thumb: {
      width: 22,
      height: 22,
      boxShadow: 3,
    },
    track: {
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 36 / 2,
      opacity: 1,
      backgroundColor: theme.palette.grey[200],
    },
    checked: {},
  }))(Switch);

  export default AntSwitch;