/*global google*/
import React, { useContext, useState, useEffect } from "react";
import {
  UserContext,
  BusContext,
  ReportContext,
  JourneyContext,
  PlaceContext,
} from "./MarkerContext";
import { RetrieveMyProtoReports } from "./data_exchange/Report_Data";
import { Marker } from "google-maps-react";
import {
  Grid,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  Typography,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ShowReports from "./ui_elements/ShowReports";
import Transport from "./ui_elements/Transport";
import CloseIconM from "../media/MyWay__back_icon.svg";
import JourneyStart from "./ui_elements/Journey";
import PlacesAutocomplete from "react-places-autocomplete";
import exit_icon from "../media/MyWay__close_icon.svg";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { JourneyNotify } from "./data_exchange/User_Data";
import { withSnackbar } from "notistack";
import * as moment from "moment";
import RecentSearches from "./ui_elements/RecentSearches";
import { SearchContext } from "./ui_elements/UIContext";
import SearchIcon from "@material-ui/icons/Search";
import ReactGA from 'react-ga4';
import SetHome from './ui_elements/SetHome';

var snackBar = null;
var directionsTo;
var directionsFrom = null;
var durationWalk = 0;
var durationBus = 0;
var arrivalBus = 0;
var arrivalWalk = 0;
var walkDirections = false;
var busDirections = false;
var googleProp;
var mapProp;
var mapCenterProp;
var busStopClick;
var navigating;
var toggleNavigating;
var searchOptions = null;
var userPos = null;
var destinationPos = null;
const arrivedRadius = 20.0;
const timer = 30000; //30s default timer for checking if closer to destination
var postcode = "";
var directionsName = "";
var directionsRenderer;
var directionsRendererPassive;
var bus;

const bigButtonStyle = {
  background: "#00c7ac",
  color: "white",
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function getPos(func) {
  navigator.geolocation.getCurrentPosition((pos) => {
    userPos = new googleProp.maps.LatLng(
      pos.coords.latitude,
      pos.coords.longitude
    );
    if (func !== undefined) {
      func();
      return;
    }
  });
}


function Directions(props) {
  snackBar = props.enqueueSnackbar;
  const [bus, setBusUse] = useState(false);
  const [journey, updateJourney] = useContext(JourneyContext);
  const [user, updateUser] = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [arrivedTimer, setArrivedTimer] = useState(timer);
  const [draw, setDraw] = useState(false);
  const [disablePinnedAreas, setDisablePinnedAreas] = useState(false);

  navigating = props.navigating;
  toggleNavigating = props.toggleNavigating;

  function hasArrived() {
    let error = false;
    if (googleProp !== undefined) {
      let distanceLeft = googleProp.maps.geometry.spherical.computeDistanceBetween(
        userPos,
        destinationPos
      );
      if (distanceLeft <= arrivedRadius){
          if (journey.length !== 0) {
            console.log("WHATS HERE")
            setArrivedTimer(10000);
          }
          //show modal that the journey has ended
          setOpen(true);
          // and here is where we should run RetrieveMyProtoReports to see if the Pinned Areas button should be enabled
          showPinnedAreas();
      }
      else{
        if (googleProp !== undefined) {
          var directionsService = googleProp.maps.DirectionsService;

          if (JSON.stringify(directionsFrom) !== JSON.stringify(userPos) && directionsFrom !== null){
            // snackBar("Diferent Coordinates")
            // return;
          }

          directionsFrom = userPos;
    
          var WalkDirectionsRequest = {
            origin: directionsFrom,
            destination: directionsTo,
            travelMode: "WALKING",
            region: "GB",
          };
    
          var BusDirectionsRequest = {
            origin: directionsFrom,
            destination: directionsTo,
            travelMode: "TRANSIT",
            transitOptions: {
              modes: ["BUS"],
              routingPreference: "FEWER_TRANSFERS",
            },
            region: "GB",
          };
    
          directionsService.prototype.route(WalkDirectionsRequest, function (
            response,
            status
          ) {
            if (status === "OK") {
              walkDirections = response;
              durationWalk = response.routes[0].legs[0].duration.text;  
              arrivalWalk = response.routes[0].legs[0].duration.value;
              arrivalWalk = moment().add(arrivalWalk, "s").format("HH:mm");
            } else {
              snackBar("Navigation query errrors")
              error = true;
              return;
            }
          });
          if (error===true){return}
          directionsService.prototype.route(BusDirectionsRequest, function (
            response,
            status
          ) {
            if (status === "OK") {
              busDirections = response;
              arrivalBus = response.routes[0].legs[0]
              if(arrivalBus.hasOwnProperty('arrival_time')){
                arrivalBus = arrivalBus.arrival_time;
                arrivalBus = moment(arrivalBus.value).format("HH:mm");
              }
              else{
                arrivalBus = response.routes[0].legs[0].duration.value;
                arrivalBus = moment().add(arrivalBus, "s").format("HH:mm");
              }
              durationBus = response.routes[0].legs[0].duration.text;
              setDraw(!draw);
            } else {
              snackBar("Directions update error" + status, {
                variant: "error",
              });
              return;
            }
          });
        }
      }
    }
  }

  const showPinnedAreas = async () => {
    const fetchMyProtoReports = await RetrieveMyProtoReports();
    if (fetchMyProtoReports === null) {
      // There aren't any proto reports / pinned areas to show
      setDisablePinnedAreas(true);
    } else {
      setDisablePinnedAreas(false);
    }
  };

  //speed up checking for journey end when close to eta
  useEffect(() => {
    if (navigating) {
      const interval = setInterval(async () => {
        if (!open && arrivedTimer >= 30000) {
          let time = null
          time = moment().add(1, "m").format("HH:mm")
          if (moment(time, "HH:mm").isSameOrAfter(moment(arrivalWalk, "HH:mm"), 'minute')){
            setArrivedTimer(10000);
          }
        }
      }, arrivedTimer/4);
      return () => clearInterval(interval);
    }
  }, [navigating]);


  useEffect(() => {
    if (navigating) {
      const interval = setInterval(async () => {
        if (!open) {
          getPos(hasArrived)
        }
      }, arrivedTimer);
      return () => clearInterval(interval);
    }
  }, [navigating]);

  function abandonJourney() {
    ReactGA.event({
      category: "Directions",
      action: "Abandon journey",
    });
    let journeyAbandoned = journey;
    journeyAbandoned.eta = "abandoned"
    // should check if there even was a notification
    JourneyNotify(journeyAbandoned);
    goBack();
  }

  function goBack() {
    toggleNavigating(false);
    directionsRenderer.prototype.setMap(null);
    walkDirections = false;
    busDirections = false;
    directionsTo = null;
    directionsFrom = null;
    setArrivedTimer(timer);
  }

  useEffect(() => {
    if (navigating) {
      directionsRenderer = googleProp.maps.DirectionsRenderer;
      directionsRenderer.prototype.setOptions({
        preserveViewport: false,
      });
      directionsRenderer.prototype.setMap(mapProp);
      if (!bus) {
        directionsRenderer.prototype.setDirections(walkDirections);
      } else {
        directionsRenderer.prototype.setDirections(busDirections);
      }
    }
  }, [navigating, bus])

  return navigating ? (
    <div className="top">
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid
          item
        >
          <IconButton
            edge="start"
            color="inherit"
            style={{ marginLeft: 2, marginRight: 2 }}
            onClick={abandonJourney}
            aria-label="close"
          >
            <img src={exit_icon} width="20rem" height="20rem"></img>
          </IconButton>
        </Grid>
        <Grid item xs={8}>
          <Transport
            durationBus={durationBus}
            durationWalk={durationWalk}
            bus={bus}
            setBusUse={setBusUse}
            walkDirections={walkDirections}
            busDirections={busDirections}
            directionsRenderer={directionsRendererPassive}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography align="left">
            Arrive: {"\n"}
            {bus ? arrivalBus : arrivalWalk}
          </Typography>
        </Grid>
      </Grid>
      <JourneyEndModal open={open} setOpen={setOpen} disablePinnedAreas={disablePinnedAreas} end={goBack} journey={journey}/>
    </div>
  ) : null;
}

export default withSnackbar(Directions);

export function DirectionsSearch(props) {
  useEffect(() => {
    ReactGA.send({hitType: "pageview",page: "/directions_search", title:"Directions"});
  }, []);

  const [open, setOpen] = useState(false);
  const [openHomeAddress, setOpenHomeAddress] = useState(false);
  const [user, updateUser] = useContext(UserContext);
  const [buses, setBuses] = useContext(BusContext);
  const [places, updatePlaces] = useContext(PlaceContext);
  const [journey, startJourney] = useState(false);
  const [searches, updateSearch] = useContext(SearchContext);

  const toolbarStyle = {
    background: "white",
    paddingBottom: "1vh",
    paddingTop: "1vh",
  };

  function handleClickOpen() {
    getPos(setSearchParams);
    setOpen(true);
    ReactGA.event({
      category: "Directions",
      action: "Open search",
    });
  }

  function handleSetHomeAddress() {
    setOpenHomeAddress(true);
  }

  function handleCloseHomeAddress() {
    setOpenHomeAddress(false);
  }

  function directionsHome() {
    ReactGA.event({
      category: "Directions",
      action: "Take Me Home",
    });
    if (places.length !== 0) {
      setOpen(true);
      directionsTo = places[0].location;
    } else {
      snackBar("Home address not set", { variant: "error" });
      setOpenHomeAddress(true);
      return;
    }
    getPos(() => getDirections(false));
  }

  function startDirections() {
    toggleNavigating(true);
    setOpen(false);
    directionsRendererPassive = googleProp.maps.DirectionsRenderer;
      directionsRendererPassive.prototype.setMap(mapProp);
      directionsRendererPassive.prototype.setOptions({
        preserveViewport: true,
      });
      ReactGA.event({
        category: "Directions",
        action: "Start navigation",
      });
  }

  function setSearchParams() {
    searchOptions = {
      location: userPos,
      radius: 700,
      types: [],
    };
  }

  function handleClose() {
    toggleNavigating(false);
    setOpen(false);
    directionsFrom = null;
    directionsTo = null;
  }

  function getPos(func) {
    navigator.geolocation.getCurrentPosition((pos) => {
      userPos = new googleProp.maps.LatLng(
        pos.coords.latitude,
        pos.coords.longitude
      );
      if (func !== undefined) {
        func();
      }
    });
  }

  function populateFields(id) {
    let direction = document.getElementById("destination_input");
    direction.value = searches[id].name+","+searches[id].location;
  }

  function getInputs() {
    if (googleProp !== undefined) {
      var input = document.getElementById("origin_input");
      if (input && input.value) {
        directionsFrom = document.getElementById("origin_input").value;
      } else {
        directionsFrom = userPos;
      }

      input = document.getElementById("destination_input");
      if (input && input.value) {
        directionsTo = document.getElementById("destination_input").value;
      } else {
        snackBar("Plese enter a destination", { variant: "error" });
        toggleNavigating(false);
        return false;
      }
      return true;
    }
  }

  async function getDirections(search) {
    if (googleProp !== undefined) {
      var directionsService = googleProp.maps.DirectionsService;
      if (search) {
        if (!getInputs()) {
          return;
        }
      } else {
        directionsFrom = userPos;
      }

      var WalkDirectionsRequest = {
        origin: directionsFrom,
        destination: directionsTo,
        travelMode: "WALKING",
        region: "GB",
      };

      var BusDirectionsRequest = {
        origin: directionsFrom,
        destination: directionsTo,
        travelMode: "TRANSIT",
        transitOptions: {
          modes: ["BUS"],
          routingPreference: "FEWER_TRANSFERS",
        },
        region: "GB",
      };

      directionsService.prototype.route(WalkDirectionsRequest, function (
        response,
        status
      ) {
        if (status === "OK") {
          walkDirections = response;
          durationWalk = response.routes[0].legs[0].duration.text;
          arrivalWalk = response.routes[0].legs[0].duration.value;
          arrivalWalk = moment().add(arrivalWalk, "s").format("HH:mm");
          destinationPos = response.routes[0].legs[0].end_location;
        } else {
          return;
          // window.alert("Directions request failed due to " + status);
        }
      });
      directionsService.prototype.route(BusDirectionsRequest, function (
        response,
        status
      ) {
        if (status === "OK") {
          busDirections = response;
          arrivalBus = response.routes[0].legs[0]
              if(arrivalBus.hasOwnProperty('arrival_time')){
                arrivalBus = arrivalBus.arrival_time;
                arrivalBus = moment(arrivalBus.value).format("HH:mm");
              }
              else{
                arrivalBus = response.routes[0].legs[0].duration.value;
                arrivalBus = moment().add(arrivalBus, "s").format("HH:mm");
              }
          durationBus = response.routes[0].legs[0].duration.text;

          let temp = response.routes[0].legs[0].end_address.split(",")
          temp.pop()
          postcode = temp.pop()
          directionsName = temp.join()
          let newSearch = searches;
          newSearch.unshift({ name: directionsName, location: postcode });
          updateSearch(newSearch);
          startJourney(true);
        } else {
          snackBar("Direction request failed, check the addresses", {
            variant: "error",
          });
        }
      });
    }
  }

  return !navigating ? (
    <div>
      <Button
        variant="outlined"
        style={bigButtonStyle}
        size="large"
        fullWidth
        onClick={directionsHome}
      >
        TAKE ME HOME
      </Button>
      <Button variant="outlined" color="primary" size="large" style={{marginTop:5, marginBottom:15}} fullWidth onClick={handleClickOpen} endIcon={<SearchIcon />}>
        WHERE TO? 
      </Button>
      <SetHome parentCallback={handleCloseHomeAddress} open={openHomeAddress} />
      <Dialog fullScreen open={open} onClose={handleClose}>
        {!journey ? (
          <>
            <AppBar position="sticky" className="directions-search">
              <Toolbar style={toolbarStyle}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="baseline"
                >
                  <Grid item xs={1}>
                    <IconButton
                      edge="start"
                      onClick={handleClose}
                      aria-label="close"
                      style={{ paddingLeft: 0, paddingRight: 1 }}
                    >
                      <img src={CloseIconM} width="20rem" height="20rem"></img>
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <LocationSearchInput
                      highlightFirstSuggestion={true}
                      idOfInput="origin_input"
                      text={"Current Location"}
                      focus={false}
                    ></LocationSearchInput>
                    <LocationSearchInput
                      idOfInput="destination_input"
                      text={"Where To?"}
                      focus={true}
                    ></LocationSearchInput>
                    <Button
                      color="primary"
                      fullWidth
                      variant="outlined"
                      onClick={() => getPos(() => getDirections(true))}
                    >
                      <Typography variant="subtitle1" >
                        NEXT
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={10}>
                <RecentSearches callback={populateFields} />
              </Grid>
            </Grid>
          </>
        ) : (
          <JourneyStart
            open={journey}
            toggleOpen={startJourney}
            navigating={navigating}
            toggleNavigating={toggleNavigating}
            closeAll={startDirections}
            time={arrivalWalk}
          ></JourneyStart>
        )}
      </Dialog>
    </div>
  ) : null;
}

////search bar with google suggestions
export class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: "" };
    this.getPos();
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    this.setState({ address });
  };

  getPos = (func) => {
    navigator.geolocation.getCurrentPosition((pos) => {
      userPos = new googleProp.maps.LatLng(
        pos.coords.latitude,
        pos.coords.longitude
      );
      searchOptions = {
        location: userPos,
        radius: 700,
        types: [],
      };
    });
  };

  render() {
    return (
      <div style={{ marginBottom: 10 }}>
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          searchOptions={searchOptions}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <TextField
                autoFocus={this.props.focus}
                variant="filled"
                fullWidth
                type="search"
                inputProps={{    style: { fontSize: 16, background: "#F4F4F6" },  }}
                {...getInputProps({
                  placeholder: this.props.text,
                  id: this.props.idOfInput,
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  const style = suggestion.active
                    ? { backgroundColor: "#00c7ac", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    );
  }
}

function JourneyEndModal(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    showReports: false,
    endNotificationSent: false
  });
  var endNotificationSent=false;

  // here we useEffect to notify the friends once (when the dialog is loaded) that the user has reached their destination.
  useEffect(() => {
    if(props.open) {
      let journeyFinished = props.journey;
      journeyFinished.eta = 0;
      if(!state.endNotificationSent) {
        setState({ ...state, endNotificationSent: true });
        JourneyNotify(journeyFinished, 0);
      }
      ReactGA.send({hitType: "modalview",page: "/journey_end", title:"Journey end"});
    }
  });

  const handleEnd = async (e) => {
    // Automatically close the dialog after 5 secs
      props.setOpen(false);
      props.end();
  };

  const showReports = () => {
    setState({ ...state, showReports: true });
  };

  // hideReports gets called when ShowReports is closed.  ShowReports passes back the number of protoReports left to complete.
  // If there are 0 reports left to complete then we close the journey end dialog.
  const hideReports = (numProtoReports) => {
    setState({ ...state, showReports: false });
    if (numProtoReports===0||numProtoReports==undefined) {
      setTimeout(function() {
        props.end();
      },5000);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      //onClose={() => props.setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          <h2 id="transition-modal-title">You have reached your destination</h2>
          <div className="FormField">
            <Button colour="primary" onClick={handleEnd}>
              Close Navigation
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              endIcon={<ArrowForwardIosIcon />}
              onClick={() => showReports()}
              disabled={props.disablePinnedAreas}
            >
              Pinned areas
            </Button>
          </div>
          {props.disablePinnedAreas === false ? (
            <ShowReports
              open={state.showReports}
              load="protoReports"
              parentCallback={() => hideReports()}
            />
          ) : (
            <></>
          )}
        </div>
      </Fade>
    </Modal>
  );
}

//dummy component to prepare props
export function PrepareProps(props) {
  mapProp = props.map;
  googleProp = props.google;
  mapCenterProp = props.mapCenter;
  busStopClick = props.onMarkerClick;
  return null;
}