import { formatPayload } from "../authentication/Utils";
import ReactGA from 'react-ga4';
const serverURL = process.env.REACT_APP_SERVER_URL;
const serverPort = process.env.REACT_APP_SERVER_PORT;

export async function RetrieveFeedback() {
  const response = await fetch(serverURL+serverPort+"/feedback", {
    method: "GET",
    mode: "cors",
    credentials: "include"
  })
    .then(data => {
      return data.json();
    })
    .catch(function(error) {
      return null;
    });
  return response;
}

export async function AddFeedback(feedback) {
    var formBody = formatPayload(feedback);
    const response = await fetch(serverURL+serverPort+"/feedback/add", {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: formBody
    });
    ReactGA.event({
      category: "Feedback",
      action: "Add Feedback",
    });
    return response.ok;
  }

export async function RemoveFeedback(feedbackID) {
  const response = await fetch(
    serverURL+serverPort+`/feedback/delete/${feedbackID}`,
    {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }
  );
  ReactGA.event({
    category: "Feedback",
    action: "Remove Feedback",
  });
  return response.ok;
}

