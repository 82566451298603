import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { GoogleLogout } from "react-google-login";
import { AuthContext } from "./AuthContext";
import { Button, Grid } from "@material-ui/core";
import ReactGA from 'react-ga4';

const gClientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const serverURL = process.env.REACT_APP_SERVER_URL;
const serverPort = process.env.REACT_APP_SERVER_PORT;

export default function SignOut() {
  const [login, setLogin] = useContext(AuthContext);
  const history = useHistory();

  async function logoutLocal() {
    const response = await fetch(serverURL+serverPort+"/signout", {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    ReactGA.event({
      category: "User",
      action: "Logged out",
    });
    localStorage.clear();
    setLogin(false);
    history.push("/signin");
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="baseline"
      spacing={3}
    >
      <Grid item xs={6}>
        <Button variant="outlined" size="medium" onClick={logoutLocal}>
          Log Out
        </Button>
      </Grid>
      <Grid item xs={6}>
        <GoogleLogout
          clientId={gClientID}
          buttonText="Logout"
          onLogoutSuccess={logoutLocal}
        />
      </Grid>
    </Grid>
  );
}
