import React, { useContext } from "react";
import ReactGA from 'react-ga4';
import "./App.css";
import {
  BusContextProvider,
  FriendContextProvider,
  UserContextProvider,
  JourneyContextProvider,
  ReportContextProvider,
  ProtoReportContextProvider,
  SensorContextProvider,
  PlaceContextProvider,
} from "./components/MarkerContext";
import {
  AuthContextProvider,
  AuthContext,
} from "./components/authentication/AuthContext";
import {
  UIContextProvider,
  SearchContextProvider,
} from "./components/ui_elements/UIContext";
import SignIn from "./components/authentication/SignIn";
import SignUp from "./components/authentication/SignUp";
import SignOut from "./components/authentication/SignOut";
import Intro from "./components/Intro";
import PrivacyPolicy from "./components/authentication/PrivacyPolicy";
import Homepage from "./components/authentication/Homepage";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { isLoggedIn } from "./components/authentication/Utils";
import MainScreen from "./components/MainScreen";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material";
import theme from "./components/theme";
import ResetPassword from "./components/authentication/ResetPassword"; 

const gaID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

export default function App() {
  ReactGA.initialize(gaID);

  // if available send Front-End-Optimisation error events to Google Analytics
  if (typeof window.onerror != undefined) {
    var original = window.onerror || function () {};
    window.onerror = function (msg, url, line, col, error) {
      original.apply(this, arguments);
      ReactGA.event({
        category: "FEO",
        action: "error",
        label: "error",
        nonInteraction: true,
      });
    };
  }
  
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={2}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        autoHideDuration={4000}
        dense
      >
        <AuthContextProvider>
          <Router>
            <div className="App">
              <div className="MapContainer">
                <Switch>
                  <Route path="/signup" component={SignUp} />
                  <Route path="/signin" component={SignIn} />
                  <Route path="/intro" component={Intro} />
                  <Route path="/privacy_policy" component={PrivacyPolicy} />
                  <Route path="/homepage" component={Homepage} />
                  <Route path="/password/reset" component={ResetPassword} />
                  <PlaceContextProvider>
                    <BusContextProvider>
                      <FriendContextProvider>
                        <UserContextProvider>
                          <JourneyContextProvider>
                            <ReportContextProvider>
                              <ProtoReportContextProvider>
                                <SensorContextProvider>
                                  <UIContextProvider>
                                    <SearchContextProvider>
                                      <PrivateRoute
                                        path="/signout"
                                        component={SignOut}
                                      />
                                      <PrivateRoute
                                        path="/"
                                        exact
                                        component={MainScreen}
                                      />
                                    </SearchContextProvider>
                                  </UIContextProvider>
                                </SensorContextProvider>
                              </ProtoReportContextProvider>
                            </ReportContextProvider>
                          </JourneyContextProvider>
                        </UserContextProvider>
                      </FriendContextProvider>
                    </BusContextProvider>
                  </PlaceContextProvider>
                </Switch>
              </div>
            </div>
          </Router>
        </AuthContextProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

// private route component which checks if the user is logged in to view the paths
function PrivateRoute({ component: Component, ...rest }) {
  var [login, setLogin] = useContext(AuthContext);
  return (
    <div>
      <Route
        {...rest}
        render={(props) =>
          isLoggedIn(login) === true ? (
            <Component {...props} />
          ) : (
            <Redirect to="/intro" />
          )
        }
      />
    </div>
  );
}
