import React, { useState, useEffect } from "react";
import exit_icon from "../../media/MyWay__close_icon.svg";
import { Card, 
  CardContent, 
  Dialog, 
  DialogContent, 
  DialogTitle, 
  Grid,
  IconButton,
  Typography } from "@mui/material";
  
export default function ShowBystander(props) {
  const [myBystander, setMyBystander] = useState([]);
  const [open, setOpen] = useState(false);


  const hideBystander = () => {
    setOpen(false);
    props.parentCallback(myBystander.length);
  };


  return (
    <Dialog
      aria-labelledby="my-bystander-dialog"
      aria-describedby="lists-active-bystander-guidance"
      open={props.open}
      onClose={hideBystander}
      scroll="paper"
      closeAfterTransition
    >
      <DialogTitle id="dialog-title">
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={11} style={{ textAlign: "center" }}>
            <Typography
              variant="h6"
              color="textPrimary"
              style={{ textAlign: "center" }}
            >
              Active bystander guidance
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              edge="start"
              color="primary"
              style={{ position: "relative", top: -2, right: 0 }}
              onClick={hideBystander}
              aria-label="close"
            >
              <img
                src={exit_icon}
                color="primary"
                width="20rem"
                height="20rem"
              ></img>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
          <Card sx={{ width: '100%', backgroundColor: '#d6003d', textAlign: 'center' }}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                <Typography
                    variant="button"
                    component="p"
                    color='#f4f4f6'
                    display="inline"
                  >
                    Put your own safety first.<br/>In an emergency call 999.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ width: '100%' }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                       <Typography
                            variant="subtitle2"
                            component="p"
                            color="textPrimary"
                            display="inline"
                        >
                            Distract - 
                        </Typography>
                        <Typography
                            variant="body2"
                            component="p"
                            color="textPrimary"
                            display="inline"
                        >
                            Interrupt, start a conversation or come up with ways to get a victim out of a situation
                        </Typography>
                    </Grid>
                </Grid>  
            </CardContent>
        </Card>
        <Card sx={{ width: '100%' }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle2"
                            component="p"
                            color="textPrimary"
                            display="inline"
                        >
                            Delegate - 
                        </Typography>
                        <Typography
                            variant="body2"
                            component="p"
                            color="textPrimary"
                            display="inline"
                        >
                            Get your friends to help, or speak to someone you trust who may be better placed to deal with the situation, or call the Police on 101.
                        </Typography>
                    </Grid>
                </Grid>  
            </CardContent>
        </Card>
        <Card sx={{ width: '100%' }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle2"
                            component="p"
                            color="textPrimary"
                            display="inline"
                        >
                            Delay - 
                        </Typography>
                        <Typography
                            variant="body2"
                            component="p"
                            color="textPrimary"
                            display="inline"
                        >
                            Intervention can still help after the event. Check on the victim and offer support.
                        </Typography>
                    </Grid>
                </Grid>  
            </CardContent>
        </Card>
        <Card sx={{ width: '100%' }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle2"
                            component="p"
                            color="textPrimary"
                            display="inline"
                        >
                            Direct - 
                        </Typography>
                        <Typography
                            variant="body2"
                            component="p"
                            color="textPrimary"
                            display="inline"
                        >
                            If it is safe to do so then you can intervene directly. Do not put yourself at risk.
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  )
}
