import React, { useState, useContext, useEffect, useRef } from "react";
import "../App.css";
import BottomMenu from "./menus/BottomMenu";
import Location from "./ui_elements/Location";
import SecTeamIcon from "../media/MyWay__secteam_icon.svg";

import ReportButton from "./ui_elements/ReportButton";
import { Grid, Paper, Button } from "@mui/material";
import MapContainer from "./MapContainer";
import { WhoAmI, Preferences, setLocalPreferences } from "./data_exchange/User_Data";
import { LoadFriends } from "./menus/ManageFriends";
import {
  UserContext,
  FriendContext,
  PlaceContext,
} from "./MarkerContext";
import user_icon from "../media/MyWay__location_icon.svg";
import Directions, { DirectionsSearch } from "./Directions";
import { withSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import ReactGA from 'react-ga4';

var snackBar = null;
var whoami;

function MainScreen(props) {
  snackBar = props.enqueueSnackbar;
  const history = useHistory();

  const [user, updateUser] = useContext(UserContext);
  const [friends, setFriends] = useContext(FriendContext);
  const [places, updatePlaces] = useContext(PlaceContext);
  const [navigating, toggleNavigating] = useState(false);
  const [menuClosed, setMenuClosed] = useState(true);
  const [refreshProtoReports, setRefreshProtoReports] = useState(false);

  const fetchData = async () => {
    try {
      whoami = await WhoAmI();
      let thisUser=user;
      thisUser.name= whoami.firstname + " " + whoami.lastname;
      thisUser.email= whoami.email;
      thisUser.userID= whoami.userid;
      thisUser.position= {"lat":whoami.latitude,"lng":whoami.longitude};
      thisUser.symbol= user_icon;
      thisUser.showLocation= whoami.showlocationbydefault;
      thisUser.clickable= user.clickable;
      thisUser.lastUpdated= whoami.lastupdated;
      thisUser.provider= whoami.provider;
      updateUser(thisUser);

      // Setup Google Analytics user info
      ReactGA.set({
        userId: thisUser.userID
      });
      ReactGA.send({hitType: "pageview",page: "/mainscreen", title:"Main Screen"});

      var preferences = await Preferences();
      setLocalPreferences(preferences);
      setFriends(await LoadFriends(friends));
      if(preferences.homeLatitude < 200){
        updatePlaces([{name:"Home", location: {lat: preferences.homeLatitude, lng: preferences.homeLongitude}}]);
      }
    } catch {
      snackBar("MainScreen couldn't contact server", { variant: "error" });
      history.push("/signin");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleMenu = async (isClosed, selectedItem) => {
    if(isClosed){
      ReactGA.send({hitType: "pageview",page: "/mainscreen", title:"Main Screen"});
    }
    //console.log("And the selected item is "+selectedItem);
    const res = await setMenuClosed(isClosed);
    //console.log("MainScreen handleMenu selected item is ", selectedItem);
  };

  const handleReportButton = async refreshProtoReports => {
    const res = await setRefreshProtoReports(refreshProtoReports);
  };

  const forceRefreshFriends = async () => {
    let tempFriends = await LoadFriends(friends);
    setTimeout(() => {
      setFriends(tempFriends);
    }, 800);  
  };

  return (
    <div className="container">
      <Directions navigating={navigating} toggleNavigating={toggleNavigating} />
      {navigating ? (
        <div className="middle_down">
          <MapContainer menuClosed={menuClosed} newReport={refreshProtoReports} forceRefreshFriends={forceRefreshFriends} parentCallback={handleReportButton} />
        </div>
      ) : (
        <div className="middle">
          <MapContainer menuClosed={menuClosed} newReport={refreshProtoReports} forceRefreshFriends={forceRefreshFriends}  parentCallback={handleReportButton} />
        </div>
      )}
      <div className="bottom" align="center">
        <Grid container >
          <Grid item xs={12}>
            <Paper elevation={1} square>
              <Grid container direction="row" justifyContent="space-around">
                <Grid item xs={10} align="center" style={{marginTop: 10}}>
                  <Paper elevation={0} square>
                    {!navigating ? <DirectionsSearch /> : null}
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} >
            <Paper elevation={1} square>
              <Grid container direction="row" justifyContent="space-around" style={{marginTop: 5}}>
                <Grid item xs={4} >
                  <ReportButton parentCallback={()=>handleReportButton(true)}/>
                </Grid>
                <Grid item xs={4}>
                {window.location.origin==="https://myway.thingitude-apps.com" ? (
                  <a href="tel:01183786300"><img src={SecTeamIcon} height="30rem" alt="Call Uni Security Emergency Line"></img></a>
                ):null}
                  </Grid>
                <Grid item xs={4}>
                  <Location forceRefresh={forceRefreshFriends}/>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Paper align="center" elevation={9} square={true}>
          <BottomMenu  parentCallback={handleMenu} newReport={refreshProtoReports}/>
        </Paper>
      </div>
    </div>
  );
}

export default withSnackbar(MainScreen);
