import React from "react";
import LensIcon from '@mui/icons-material/Lens';


export default function Severity(severity) {
    var severityColor;
    if(severity.severity==="high") {
      severityColor="#d6003d";
    } else if (severity.severity==="medium") {
        severityColor="#ffbf00";
    } else if (severity.severity==="low") {
        severityColor="00c7ac";
    }
    return (
      <LensIcon style={{color:severityColor, fontSize:10}} fontSize="small" />
    )
  }