import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import IsLoggedIn, { formatPayload } from "./Utils";
import { AuthContext } from "./AuthContext";
import { ThemeProvider } from "@material-ui/core/styles";
import themeSignUpIn from "../themeSignUpIn";
import { withSnackbar } from "notistack";
import ReactGA from 'react-ga4';

// var logInStatus = [false, ""];
var snackBar = null;
const serverURL = process.env.REACT_APP_SERVER_URL;
const serverPort = process.env.REACT_APP_SERVER_PORT;

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  whiteText: {
    color: "#ffffff",
  },
  whiteButton: {
    color: "#ffffff",
    margin: theme.spacing(1, 0, 3),
  },
}));

function ResetPassword(props) {
  snackBar = props.enqueueSnackbar;
  const [data, setData] = useState({
    email_confirm: "",
    password1: "",
    password2: "",
  });

  const [login, setLogin] = useContext(AuthContext);
  const history = useHistory();
  const classes = useStyles();

  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value.trim(),
    });
  };

  async function resetPassword(e) {
    e.preventDefault();

    // get random num
    let url = window.location.search;
    var searchParams = new URLSearchParams(url);
    var randomNum = searchParams.getAll("rand");

    if (data.password1 === data.password2 && data.password1 !== "") {
      var formBody = formatPayload({email: data.email_confirm, password: data.password1, random: randomNum[0]});
      const response = await fetch(serverURL+serverPort+"/password/reset", {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      });
      if (response.status === 200) {
        snackBar("Password reset successful", {
          variant: "success",
        });
        // logInStatus = IsLoggedIn(login);
        ReactGA.event({
          category: "User",
          action: "Password changed",
        });
        history.push("/signin");
        setData("");
      }
      else {
        snackBar("Passwords couldn't be reset", {
          variant: "error",
        });
      }
    } 
    else {
      snackBar("Passwords don't mach, try again", {
        variant: "error",
      });
    }
  }

  return (
    <ThemeProvider theme={themeSignUpIn}>
      <Container
        component="main"
        maxWidth="xs"
        className="container"
        style={{
          background: `linear-gradient(rgba(00,199,172,1),transparent)`,
          backgroundColor: "#2d2d68",
          maxHeight: window.innerHeight,
        }}
      >
        <Grid container alignItems="center" style={{ marginTop: "15vh" }}>
          <form
            onSubmit={resetPassword}
            className={classes.form}
            autoComplete="off"
          >
            <Grid
              container
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12} align="center">
                <TextField
                  fullWidth
                  id="email_confirm"
                  name="email_confirm"
                  label="Confirm Email Address"
                  data={data.email_confirm}
                  onChange={onChange}
                  required
                  inputProps={{
                    type: "email",
                    required: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} align="center">
                <TextField
                  fullWidth
                  id="password1"
                  name="password1"
                  label="New Password"
                  data={data.password1}
                  onChange={onChange}
                  required
                  inputProps={{
                    type: "password",
                    required: true,
                    minLength: 8,
                  }}
                />
              </Grid>
              <Grid item xs={12} align="center">
                <TextField
                  fullWidth
                  label="Confirm Password"
                  name="password2"
                  id="password2"
                  data={data.password2}
                  onChange={onChange}
                  required
                  inputProps={{
                    type: "password",
                    required: true,
                    minLength: 8,
                  }}
                />
              </Grid>
              <Grid item xs={12} align="center">
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  style={{ marginBottom: 0 }}
                >
                  <Typography variant="h6" color="textPrimary">
                    Reset Password
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </form>
          <Grid item xs={12} align="center" style={{"marginTop":"7vh"}}>
            <Typography variant="body1" color="textPrimary">
              Haven't got an account?
            </Typography>
            <Button fullWidth href="/signup" variant="outlined" color="primary" className={classes.whiteButton}>
              Sign Up
            </Button>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

export default withSnackbar(ResetPassword);
