import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { initializeFirebase } from './pushNotification';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
        <App />,
    document.getElementById('root')
);

serviceWorkerRegistration.register();
const FirebaseApp=initializeFirebase();
console.log("Just initialized firebase");
console.log(FirebaseApp);
reportWebVitals();