import React, { useState, useEffect } from "react";
import { NewReport, RegisterProtoReport } from "../data_exchange/Report_Data";
import Severity from "../ui_elements/Severity";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createTheme, useTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import ReactGA from "react-ga4";
import { withSnackbar } from 'notistack';
import { Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogActions,
  Grid, 
  ToggleButton,
  ToggleButtonGroup,
  Typography, 
  TextField } from "@mui/material";

var snackBar = null;

const useStyles = makeStyles(theme => ({
  modal: {
    width: "80%",
    padding: theme.spacing(2, 4, 3),
    backgroundColor: theme.palette.background.paper
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  }
}));

const theme = createTheme({
  palette: {
    text: {
      primary: '#2D2D68',
      secondary: '#3f3f3f',
      disabled: '#d5d5db',
      hint: '#9d9da5'
    },
    typography: {
      body1: {
        fontSize: "1rem",
      },
      body2: {
        fontSize: "0.875rem",
      },
      button:{
        fontSize: "0.875rem",
      }
    }
  }
});
  

function AddReport(props) {
  snackBar = props.enqueueSnackbar;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [state, setState] =useState({
    incidentType: "other",
    description: "",
    severity: "high",
    address: ""
  });

  useEffect(() => {
    if(props.open){
      ReactGA.send({hitType: "pageview",page: "/new_report", title:"New Report"});
    }
  }, []);


  const handleIncidentType = e => {
    setState({ ...state, incidentType: e.target.value });
  };

  const handleDescriptionChange = e => {
    setState({ ...state, description: e.target.value });
  };

  const handleAddressChange = e => {
    setState({ ...state, address: e.target.value });
  };

  function handleSeverityChange (newSeverity) {
    setState({ ...state, severity: newSeverity });
  };
  
  const cancelForm=() => {
    setState({ ...state, description: "",severity: "", address: "" });
    props.parentCallback(); 
  }

    const protoReport = async () => {
        var address="";
        await RegisterProtoReport(props.lat, props.lng, address);
        snackBar("Reported: add details when you finish your journey", {variant:"success"});
        props.parentCallback();
    };
    
    const createReport = async () => {
        const newReport={
            description: state.incidentType+" - "+state.description,
            severity: state.severity,
            address: state.address
        }
        // Update the database
        await NewReport(props.lat, props.lng, newReport);
        setState({ ...state, description: "",severity: "", address:"" });

        // Close the form and let the parent know
        props.parentCallback(); 
    }

return (
    <ThemeProvider theme={theme}>
    <Dialog
        fullScreen={fullScreen}
        aria-labelledby="new-report-dialog"
        aria-describedby="allows a user to create a reported incident"
        open={props.open}
        onClose={cancelForm}
        scroll="paper"
        closeAfterTransition
    >
        <DialogContent>
            <Grid container spacing={3}>
                <Grid item xs={12} >
                <img src={"https://maps.googleapis.com/maps/api/staticmap?center="
                    +props.lat+","+props.lng
                    +"&zoom=15&size=350x150&maptype=roadmap&markers=color:red%7C"
                    +props.lat+","+props.lng
                    +"&key="+process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                    alt="Map showing the location of the reported incident"
                ></img>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.title} variant="h6" color="textPrimary" gutterBottom>
                        New Incident
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        What type of incident are you warning people about?
                    </Typography>
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center">
                  <ToggleButtonGroup
                    size="small"
                    color="primary"
                    value={state.incidentType}
                    exclusive
                    onChange={handleIncidentType}
                    aria-label="Incident type"
                  >
                    <ToggleButton value="harassment">Harassment</ToggleButton>
                    <ToggleButton value="fighting">Fighting</ToggleButton>
                    <ToggleButton value="spiking">Spiking</ToggleButton>
                    <ToggleButton value="other">Other</ToggleButton>

                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">
                        Give more details of incident here:
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                <TextField 
                    id="description" 
                    label="Description (summarize the situation)" 
                    variant="outlined" 
                    placeholder="e.g. Rowdy drunks shouting at women"
                    fullWidth 
                    multiline
                    rows="2" 
                    onChange={handleDescriptionChange}
                />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                      Please also consider reporting serious incidents to the Police by calling 101 for non-emergencies or 999 for emergencies.
                  </Typography>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={cancelForm} color="secondary">
                Cancel
            </Button>
            <Button variant="outlined" onClick={protoReport} color="primary">
                Finish Later
            </Button>
            <Button variant="contained" onClick={createReport} color="primary">
                Publish Report
            </Button>
        </DialogActions>
    </Dialog>
    </ThemeProvider>
    );
}

export default withSnackbar(AddReport);
