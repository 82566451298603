import React, { useState, useContext, useEffect } from "react";
import { FriendContext, UserContext } from "../MarkerContext";
import AntSwitch from "../ui_elements/AntSwitch";
import exit_icon from "../../media/MyWay__close_icon.svg";
import QRCode from "qrcode.react";
import QrReader from "react-qr-reader";
import {
  RetrieveFriends,
  AddFriend,
  RemoveFriend,
} from "../data_exchange/Friend_Data";
import friend_icon from "../../media/MyWay__friend_location_icon.svg";
import { withSnackbar } from "notistack";
import FriendLocationShare from "../ui_elements/FriendLocationShare";
import { UpdatePreferences } from "../data_exchange/User_Data";
import ReactGA from "react-ga4";
import { Button,
    Card,
    CardContent,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Typography, 
} from "@mui/material";

var snackBar = null;
var refeshFriends = null;
var intFrameWidth = window.innerWidth;
var scanner = false;
const serverURL = process.env.REACT_APP_SERVER_URL;
const serverPort = process.env.REACT_APP_SERVER_PORT;

const bigButtonStyle = {
  background: "#00c7ac",
  color: "white",
};

export async function LoadFriends(friends) {
  var previousFriendState;
  loadUserPrefs();

  function loadUserPrefs() {
    if (Object.entries(friends).length === 0) {
      if (localStorage.friends == null) {
        setConfig(true);
      }
    }
    if (friends != null && friends.length > 0) {
      if (localStorage.friends == null) {
        previousFriendState = friends;
        setConfig(false);
      }
    }
    let prefs = localStorage.friends;
    previousFriendState = JSON.parse(prefs);
  }

  function setConfig(empty) {
    if (empty) {
      //when no config
      localStorage.setItem(
        "friends",
        JSON.stringify({ id: -1, visible: false })
      );
    }
    if (friends.length > 0) {
      let currentState = friends.map((element) => {
        return { id: element.userID, visible: element.visible };
      });
      localStorage.setItem("friends", JSON.stringify(currentState));
    }
  }

  function wasVisible(userID) {
    try {
      var user = previousFriendState.find((friend) => friend.id === userID);
      return user.visible;
    } catch {
      return true;
    }
  }

  const fetchFriends = await RetrieveFriends();
  var arrayOfFriends = [];
  if (fetchFriends !== null) {
    arrayOfFriends = fetchFriends.map(
      (friend) =>
        (friend = {
          name: friend.firstname + " " + friend.lastname,
          position: { lat: friend.latitude, lng: friend.longitude },
          userID: friend.friendID,
          clickable: true,
          type: "friend",
          lastUpdated: friend.lastseen,
          visible: wasVisible(friend.friendID),
          locationShared: friend.showlocation,
          symbol: friend_icon,
        })
    );
    setConfig(false);
    return arrayOfFriends;
  }
  setConfig(true);
  return arrayOfFriends;
}


function ManageFriends(props) {
  snackBar = props.enqueueSnackbar;
  refeshFriends = props.refeshFriends;
  const [friends, setFriends] = useContext(FriendContext);
  const [user, updateUser] = useContext(UserContext);
  const [open, setOpen] = useState(false);

  const fetchData = async () => {
    try {
      setFriends(await LoadFriends(friends));
    } catch {
      snackBar("ERROR Loading friends", { variant: "error" });
    }
  };

  useEffect(() => {
    ReactGA.send({hitType: "pageview",page: "/friend_menu", title:"Friend Menu"});
    fetchData();
  }, []);

  async function toggleFriendVisibility(i) {
    let friendsChanges = [...friends];
    let update = !friendsChanges[i].visible;
    friendsChanges[i].visible = update;
    //update the storage
    let currentState = friendsChanges.map((element) => {
      return { id: element.userID, visible: element.visible };
    });
    localStorage.setItem("friends", JSON.stringify(currentState));
    UpdatePreferences();
    await setFriends(friendsChanges);
    fetchData();
    // Log that a friends visibility has been toggled
    ReactGA.event({
      category: "Social",
      action: "Location sharing with a friend set to " +update
    });
  }

  //removes marker from the context
  async function removeFriend(index) {
    const response = await RemoveFriend(friends[index].userID);
    if (response) {
      snackBar("Friend Removed", { variant: "success" });
    }
    //probably not needed
    //check if server received it and only then
    let newFriends = friends.filter((item) => item !== friends[index]);
    setFriends(newFriends);
  }

  function showQRCode() {
    setOpen(true);
  }

  function hideQRCode() {
    let timing = 0;
    if (scanner) {
      timing = 1000;
    }

    setTimeout(() => {
      setOpen(false);
      fetchData();
      scanner = false;
    }, timing);
  }

  function AddFriendQRCode() {
    const [scan, toggleScan] = useState(false);

    useEffect(() => {
      if (open) {
        ReactGA.send({hitType: "modalview",page: "/friend_qr_code", title:"Show QR Code"});
      }
    }, []);

    return (
      <div className="FormField">
        <Dialog
          open={open}
          onClose={hideQRCode}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" color="textSecondary">
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={11} style={{ textAlign: "center" }}>
                <Typography
                  variant="h6"
                  color="textPrimary"
                  style={{ textAlign: "center" }}
                >
                  Add a Friend
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  edge="start"
                  color="primary"
                  style={{ position: "relative", top: -2, right: 0 }}
                  onClick={hideQRCode}
                  aria-label="close"
                >
                  <img
                    src={exit_icon}
                    color="primary"
                    width="20rem"
                    height="20rem"
                  ></img>
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            {scan ? (
              <ScanFriend
                className="add-friend-scanner"
                toggleScan={toggleScan}
                closeAll={hideQRCode}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <QRCode
                  value={serverURL+serverPort+`/friends/add/${user.userID}`}
                  size={intFrameWidth * 0.7}
                  position={"relative"}
                  bgColor={"#ffffff"}
                  fgColor={"#000000"}
                  level={"L"}
                  includeMargin={false}
                  renderAs={"svg"}
                />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            {scan ? (
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item xs={11}>
                  <Button
                    onClick={() => toggleScan(false)}
                    color="primary"
                    fullWidth
                    variant="contained"
                  >
                    My QR Code
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item xs={11}>
                  <Button
                    onClick={() => toggleScan(true)}
                    color="primary"
                    fullWidth
                    variant="contained"
                  >
                    Show QR Scanner
                  </Button>
                </Grid>
              </Grid>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }


  // main render
  return (
    <Dialog
        fullWidth={true}
        open={props.open}
        onClose={props.parentCallback}
    >
      <DialogTitle id="add-friend" color="textSecondary">
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={11} style={{ textAlign: "center" }}>
            <Typography
              variant="h6"
              color="textPrimary"
              style={{ textAlign: "center" }}
            >
              Friends
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              edge="start"
              color="primary"
              style={{ position: "relative", top: -2, right: 0 }}
              onClick={props.parentCallback}
              aria-label="close"
            >
              <img
                src={exit_icon}
                color="primary"
                width="20rem"
                height="20rem"
              ></img>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card style={{ margin: 0 }}>
          <CardContent>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
            >
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  style={bigButtonStyle}
                  onClick={showQRCode}
                  fullWidth
                >
                  Add <br/>Friend
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card style={{ margin: 1 }}>
          <CardContent>
            <div style={{ marginLeft: 10 }}>
              <Typography variant="subtitle1" color="textPrimary">
                Friends on MyWay
              </Typography>
            </div>
            <List className="FriendTextList">
              {friends.length === 0 || friends[0].userID === ""
                ? <div style={{ marginLeft: 10 }}><Typography variant="body" color="textSecondary">No friends added yet</Typography></div>
                : friends.map((friend, i) => {
                    const labelId = `friend-no-${i}`;
                    return (
                      <ListItem
                        key={i}
                        role={undefined}
                        dense
                        button
                        onClick={() => toggleFriendVisibility(i)}
                      >
                        <ListItemIcon>
                          <Grid container spacing={3}>
                            <Grid item style={{ "marginTop": "5px" }}>
                              <AntSwitch
                                checked={friend.visible}
                                disableRipple
                                color="primary"
                                inputProps={{ "aria-label": " labelId " }}
                              />
                            </Grid>
                            <Grid item style={{ margin: "0px 12px 5px 0px" }}>
                              <FriendLocationShare
                                locationShared={friend.locationShared}
                              />
                            </Grid>
                          </Grid>
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={friend.name} />
                        <ListItemSecondaryAction>
                          <Button
                            edge="end"
                            aria-label="remove"
                            onClick={() => removeFriend(i)}
                            size="small"
                            color="secondary"
                          >
                            Remove
                          </Button>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
            </List>
          </CardContent>
        </Card>
      </Grid>
      {open ? <AddFriendQRCode id="add_friend_dialog" /> : null}
    </Grid>
    </Dialog>
  );
}


export function ScanFriend(props) {
  const [friends, setFriends] = useContext(FriendContext);
  scanner = true;
  ReactGA.send({hitType: "modalview",page: "/friend_qr_scan", title:"Scan Friend QR"});

  const handleScan = async (url) => {
    if (url) {
      if (url.includes(serverURL)) {
        try {
          const response = await AddFriend(url);
          if (response) {
            setFriends(await LoadFriends(friends));
            snackBar("Friend Added", { variant: "success" });
            props.closeAll();
            props.toggleScan();
          }
        } catch {
          snackBar("Error encountered try again", { variant: "error" });
        }
      }
    }
  };

  const handleError = (err) => {
    snackBar("Error encountered try again", { variant: "error" });
  };

  return (
    <div id="QR_reader">
      <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ height: "100%", width: intFrameWidth * 0.7 }}
      />
    </div>
  );
}

export default withSnackbar(ManageFriends);
