// Functions to retrieve sensor info from the server
const serverURL = process.env.REACT_APP_SERVER_URL;
const serverPort = process.env.REACT_APP_SERVER_PORT;

export async function RetrieveRelevantSensorData(showLight, showPeople) {
  const response = await fetch(serverURL+serverPort+"/api/relevantsensors/"+showLight+"/"+showPeople, {
    method: "GET",
    mode: "cors",
    credentials: "include"
  })
    .then(data => {
      return data.json();
    })
    .catch(function(error) {
      console.log("Looks like there was a problem getting relevant sensor data: \n", error);
      return null;
    });
  return response;
}

export async function RetrievePeople() {
  const response = await fetch(serverURL+serverPort+"/peoplemovement", {
    method: "GET",
    mode: "cors",
    credentials: "include"
  })
    .catch(function(error) {
      console.log("Looks like there was a problem getting people movement data: \n", error);
      return null;
    })
    .then(data => {
      return data.json();
    });
  return response;
}

export async function RetrieveLighting() {
    const response = await fetch(serverURL+serverPort+"/lightlevels", {
      method: "GET",
      mode: "cors",
      credentials: "include"
    })
      .catch(function(error) {
        console.log("Looks like there was a problem getting light level data: \n", error);
        return null;
      })
      .then(data => {
        return data.json();
      });
    return response;
  }
  