import React, {useState} from "react";
import { RegisterProtoReport } from "../data_exchange/Report_Data";
import { Button } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import ReportButtonIcon from "../../media/MyWay__report_icon.svg";
import AddReport from "./AddReport";
var snackBar = null;

function ReportButton(props) {
  snackBar = props.enqueueSnackbar;
  const [showReport, setShowReport] = useState(false);
  const [coords, setCoords] = useState( {"lat":51.4543, "lng":-0.9781});

  const openNewReport = async () => {
    //console.log("Opening new report");
    await navigator.geolocation.getCurrentPosition(pos => {
      const coords = {
        lat: pos.coords.latitude,
        lng: pos.coords.longitude
      };
      setCoords({"lat":coords.lat, "lng": coords.lng});
    });
    setShowReport(true);
  }

  const closeReportDialog = () => {
    //console.log("Closing the report dialog");
    setShowReport(false);
    props.parentCallback(true);
  }




  return (
    <div>
      <Button color="secondary" size="medium" onClick={() => openNewReport()}><img src={ReportButtonIcon} height="30rem" alt="Register an incident with this Report button"></img></Button>
      <AddReport open={showReport} lat={coords.lat} lng={coords.lng} parentCallback={() => closeReportDialog()} />
    </div>
    );
}

export default withSnackbar(ReportButton);
