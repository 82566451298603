import React, { useState, useContext, useEffect } from "react";
import GoogleLogin from "react-google-login";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import IsLoggedIn, { formatPayload } from "./Utils";
import { UpdatePushNotificationPreferences } from "./SignInLogic";
import { AuthContext } from "./AuthContext";
import { ThemeProvider } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import themeSignUpIn from "../themeSignUpIn";
import { withSnackbar } from "notistack";
import ReactGA from "react-ga4";

const gClientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const serverURL = process.env.REACT_APP_SERVER_URL;
const serverPort = process.env.REACT_APP_SERVER_PORT;
var logInStatus = [false, ""];
var recovery_email;
var snackBar = null;

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "15vh",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  whiteText: {
    color: "#ffffff",
  },
  whiteButton: {
    color: "#ffffff",
    margin: theme.spacing(1, 0, 3),
  },
}));

function SignIn(props) {
  snackBar = props.enqueueSnackbar;
  ReactGA.send({hitType: "pageview",page: "/signin", title:"Sign-in"});

  const [data, setData] = useState({
    provider: "local",
    email: "",
    password: "",
    pushtoken: "",
  });
  const [open, setOpen] = useState(false);
  const [login, setLogin] = useContext(AuthContext);
  const history = useHistory();
  const classes = useStyles();

  const loginGoogle = async (e) => {
    const formBody = formatPayload({
      provider: "google",
      email: e.profileObj.email,
    });
    try {
      const response = await fetch(serverURL+serverPort+"/signin", {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      });
      if (response.status === 200) {
        snackBar("Google Sign In successful", {
          variant: "success",
        });
        logInStatus = IsLoggedIn(login);
        ReactGA.event({
          category: "User",
          action: "Sign In via Google",
        });
      }
    } catch {
      snackBar("Google Sign In encountered an error", {
        variant: "error",
      });
      return;
    }
    setLogin(logInStatus);
    const pushResponse= await UpdatePushNotificationPreferences(
      data,
      serverURL + serverPort + "/updatenotificationsettings",
      snackBar
    );
    history.push("/");
    setData("");
  };

  const errorGoogle = (e) => {
    snackBar("Google Sign In encountered an error, try again", {
      variant: "error",
    });
  };

  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("User clicked Sign In");
      var formBody = formatPayload(data);
      //console.log(formBody);
      //console.log("Server URL is "+serverURL+serverPort+"/signin");
      const response = await fetch(serverURL+serverPort+"/signin", {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      });
      if (response.status === 200) {
        ReactGA.event({
          category: "User",
          action: "Sign In via Email",
        });
        logInStatus = IsLoggedIn(login);
        setLogin(logInStatus);
        const pushResponse= await UpdatePushNotificationPreferences(
          data,
          serverURL + serverPort + "/updatenotificationsettings",
          snackBar
        );
        history.push("/");
        setData("");
        snackBar("Signed In", {
          variant: "success",
        });
        console.log("User Signed In with status 200");
      }
    } catch {
      snackBar(
        "Sign In credentials are incorrect or can't connect to server, please try again",
        {
          variant: "error",
        }
      );
      console.log("User Signed In caught an error.");
    }
  };

  function ResetPasswordComponent(props) {
    const [visible, setVisible] = useState(props.open);
    useEffect(() => {
      if (visible) {
        ReactGA.send({hitType: "modalview",page: "/reset_password", title:"Reset Password"});
      }
    }, [visible])
    

    async function resetPassword() {
      recovery_email = document.getElementById("recovery_email_input").value;
      if (recovery_email === "" || recovery_email === undefined) {
        snackBar("No email found");
        return;
      }
      var formBody = formatPayload({ email: recovery_email });
      const response = await fetch(
        serverURL+serverPort+"/password/reset/email",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formBody,
        }
      );
      if (response.status === 200) {
        ReactGA.event({
          category: "User",
          action: "Reset password email sent",
        });
        snackBar("Passwords reset email is sent", {
          variant: "success",
        });
        close();
      } else {
        snackBar("Passwords reset email not sent", {
          variant: "error",
        });
      }
    }

    function close() {
      setVisible(false);
      setOpen(false);
    }

    return (
      <Dialog
        open={visible}
        onClose={close}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            background: `linear-gradient(rgba(00,199,172,1),transparent)`,
            backgroundColor: "#2d2d68",
          },
        }}
      >
        <DialogTitle id="form-dialog-title" color="textSecondary">
          Reset Password
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the email address that you registered the account with. A
            recovery email will be sent with further instructions.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="recovery_email_input"
            placeholder="example@email.com"
            label="Email address"
            fullWidth
            inputProps={{ type: "email", required: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={resetPassword}
            color="primary"
            fullWidth
            variant="contained"
          >
            Reset Password
          </Button>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <ThemeProvider theme={themeSignUpIn}>
      <Container
        component="main"
        maxWidth="xs"
        className="container"
        style={{
          background: `linear-gradient(rgba(00,199,172,1),transparent)`,
          backgroundColor: "#2d2d68",
          maxHeight: window.innerHeight,
        }}
      >
        <Grid container alignItems="center" >
          <form
            onSubmit={handleSubmit}
            className={classes.form}
            autoComplete="off"
          >
            <Grid
              container
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12} align="center">
                <TextField
                  fullWidth
                  type="email"
                  id="email"
                  name="email"
                  label="Email"
                  data={data.email}
                  onChange={onChange}
                  required
                />
              </Grid>
              <Grid item xs={12} align="center">
                <TextField
                  fullWidth
                  type="password"
                  label="Password"
                  name="password"
                  id="password"
                  minLength="8"
                  data={data.password}
                  onChange={onChange}
                  required
                  inputProps={{ type: "password", required: true }}
                />
              </Grid>
              <Grid item xs={12} align="center">
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  style={{ marginBottom: 0 }}
                >
                  <Typography variant="h6" color="textPrimary">
                    SIGN IN
                  </Typography>
                </Button>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  style={{ margin: 8 }}
                >
                  OR
                </Typography>
                <GoogleLogin
                  clientId={gClientID}
                  buttonText="Login with Google"
                  onSuccess={loginGoogle}
                  onFailure={errorGoogle}
                  cookiePolicy={"single_host_origin"}
                />
              </Grid>
            </Grid>
          </form>
          <Grid item xs={12} align="center" style={{ marginTop: "7vh" }}>
            <Typography variant="body1" color="textPrimary">
              Haven't got an account?
            </Typography>
            <Button
              fullWidth
              href="/signup"
              variant="outlined"
              color="primary"
              className={classes.whiteButton}
            >
              Sign Up
            </Button>
            <Button
              fullWidth
              onClick={() => {
                setOpen(true);
              }}
              variant="outlined"
              color="primary"
              className={classes.whiteButton}
            >
              Reset password
            </Button>
            <Button
              fullWidth
              href="/privacy_policy"
              color="primary"
              className={classes.whiteButton}
            >
              Privacy Policy
            </Button>
          </Grid>
        </Grid>
        <ResetPasswordComponent open={open} />
      </Container>
    </ThemeProvider>
  );
}

export default withSnackbar(SignIn);
