import React, { useState, useEffect } from "react";
import AntSwitch from "../ui_elements/AntSwitch";
import { ParseBool } from "../authentication/Utils";
import { UpdatePreferences } from "../data_exchange/User_Data";
import ReactGA from "react-ga4";
import exit_icon from "../../media/MyWay__close_icon.svg";
import { Button,
    Card,
    CardContent,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    IconButton,
    Slider,
    ToggleButton,
    ToggleButtonGroup,
    Typography, 
    TextField } from "@mui/material";
    import PeopleIcon from '../../media/MyWay__people_icon.svg';
    import StreetlightIcon from "../../media/MyWay__streetlight_icon.svg";
    import ReportIcon from "../../media/MyWay__pin_high_icon.svg";
 
export default function AdjustFilters(props) {
  useEffect(() => {
    ReactGA.send({hitType: "pageview",page: "/filter_menu", title:"Filter Menu"});
  }, []);

  const [state, setState] = useState({
    showPeople: ParseBool(localStorage.getItem("showPeople")),
    showLighting: ParseBool(localStorage.getItem("showLighting")),
    showReports: ParseBool(localStorage.getItem("showReports")),
  });

  const [showReportSeverity, setShowReportSeverity] = useState(
    localStorage.getItem("showReportSeverity")
      ? localStorage.getItem("showReportSeverity")
      : "all"
  );
  const [showReportsWithin, setShowReportsWithin] = useState(
    localStorage.getItem("showReportsWithin")
      ? localStorage.getItem("showReportsWithin")
      : "3"
  );

  const sliderMarks = [
    { value: 1, label: "15m" },
    { value: 2, label: "30m" },
    { value: 3, label: "1hr" },
    { value: 4, label: "5hrs" },
    { value: 5, label: "1day" },
  ];

  const handleShowReportSeverity = (event, newSeverity) => {
    // var selected;
    // var color;
    if (newSeverity.length) {
      setShowReportSeverity(newSeverity);
      localStorage.setItem("showReportSeverity", newSeverity);
      // Log that the user has changed the report severity they want to see
      ReactGA.event({
        category: "Filters",
        action: "Updated report severity cut off to " + newSeverity,
      });
      UpdatePreferences();
    }
  };

  const handleShowReportsWithin = (event, newTime) => {
    setShowReportsWithin(newTime);
    localStorage.setItem("showReportsWithin", newTime);
    UpdatePreferences();
    // Log that the user has changed the ShowReportsWithin time
    ReactGA.event({
      category: "Filters",
      action: "Updated report time cut off to " + newTime,
    });
  };

  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
    //set the name and value in local storage - eg 'showReports=true'
    localStorage.setItem(name, event.target.checked);
    UpdatePreferences();
    ReactGA.event({
      category: "Filters",
      action: "Updated " + name + " to " + event.target.checked,
    });
  };

  function valuetext(value) {
    return `${value} mins ago`;
  }

  function valueLabelFormat(value) {
    return sliderMarks.findIndex((mark) => mark.value === value) + 1;
  }

  return (
    <Dialog 
      fullWidth={true}
      open={props.open}
      onClose={props.parentCallback}
      >
      <DialogTitle id="form-dialog-title" color="textSecondary">
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={11} style={{ textAlign: "center" }}>
            <Typography
              variant="h6"
              color="textPrimary"
              style={{ textAlign: "center" }}
            >
            What I want on the map
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              edge="start"
              color="primary"
              style={{ position: "relative", top: -2, right: 0 }}
              onClick={props.parentCallback}
              aria-label="close"
            >
              <img
                src={exit_icon}
                color="primary"
                width="20rem"
                height="20rem"
              ></img>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Card style={{ margin: 1 }}>
        <CardContent>
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <img src={PeopleIcon} width="46rem" height="34rem" style={{marginLeft:-5}}></img>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="subtitle1" color="textPrimary">
                People in the area
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <AntSwitch
                checked={state.showPeople}
                onChange={handleChange("showPeople")}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Show people movement on the streets
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ margin: 1 }}>
        <CardContent>
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <img src={StreetlightIcon} width="20rem" height="26rem"></img>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="subtitle1" color="textPrimary">
                Lighting
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <AntSwitch
                checked={state.showLighting}
                onChange={handleChange("showLighting")}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Change my route to avoid poorly lit streets
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ margin: 1 }}>
        <CardContent>
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <img src={ReportIcon} width="20rem" height="24rem"></img>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="subtitle1" color="textPrimary">
                Reports
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <AntSwitch
                checked={state.showReports}
                onChange={handleChange("showReports")}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Show me incidents reported within the last:
              </Typography>
              <Slider
                id="reportsTimeSlider"
                disabled={!state.showReports}
                style={{ color: "#2d2d68" }}
                defaultValue={3}
                value={showReportsWithin}
                valueLabelFormat={valueLabelFormat}
                getAriaValueText={valuetext}
                aria-labelledby="time-slider"
                step={null}
                max={6}
                marks={sliderMarks}
                onChange={handleShowReportsWithin}
              />
            </Grid>
            <Grid item xs={12} align="center">
              <Typography variant="body2" color="textSecondary">
                Show me incidents with severity of at least:
              </Typography>
              <ToggleButtonGroup
                id="reportsSeverityToggles"
                value={showReportSeverity}
                exclusive
                onChange={handleShowReportSeverity}
                aria-label="report severity"
                style={{ align: "center" }}
                size="small"
              >
                <ToggleButton
                  id="tbAll"
                  disabled={!state.showReports}
                  style={
                    showReportSeverity == "all"
                      ? {
                          backgroundColor: "rgba(45,45,104,0.2)",
                          border: "1px solid #2d2d68",
                          margin: 2,
                        }
                      : { border: "1px solid #3f3f3f", margin: 2 }
                  }
                  value="all"
                >
                  All
                </ToggleButton>
                <ToggleButton
                  id="tbLow"
                  disabled={!state.showReports}
                  style={
                    showReportSeverity == "low"
                      ? {
                          backgroundColor: "rgba(00,199,172,0.2)",
                          border: "1px solid #00c7ac",
                          margin: 2,
                        }
                      : { border: "1px solid #3f3f3f", margin: 2 }
                  }
                  value="low"
                >
                  Low
                </ToggleButton>
                <ToggleButton
                  id="tbMedium"
                  disabled={!state.showReports}
                  style={
                    showReportSeverity == "medium"
                      ? {
                          backgroundColor: "rgba(251,251,00,0.2)",
                          border: "1px solid #fbfb00",
                          margin: 2,
                        }
                      : { border: "1px solid #3f3f3f", margin: 2 }
                  }
                  value="medium"
                >
                  Medium
                </ToggleButton>
                <ToggleButton
                  id="tbHigh"
                  disabled={!state.showReports}
                  style={
                    showReportSeverity == "high"
                      ? {
                          backgroundColor: "rgba(214,00,61,0.2)",
                          border: "1px solid #d6003d",
                          margin: 2,
                        }
                      : { border: "1px solid #3f3f3f", margin: 2 }
                  }
                  value="high"
                >
                  High
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Dialog>
  );
}
