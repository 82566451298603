import React, { useState, useContext, useEffect } from "react";
import { FriendContext, JourneyContext } from "../MarkerContext";
import { makeStyles } from "@mui/styles";
import {
  List,
  ListItem,
  Grid,
  Paper,
  Typography,
  ListItemText,
  ListItemIcon,
  Checkbox,
  IconButton,
  Button,
  AppBar,
  Toolbar
} from "@mui/material";
import { JourneyNotify } from "../data_exchange/User_Data";
import CloseIcon from "../../media/MyWay__back_icon.svg";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ReactGA from 'react-ga4';

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  }
}));

var open;
var toggleOpen;
var navigating;
var toggleNavigating;
var closeAll;
var eta = 0;

export default function JourneyStart(props) {
  open = props.open;
  toggleOpen = props.toggleOpen;
  navigating = props.navigating;
  toggleNavigating = props.toggleNavigating;
  closeAll = props.closeAll;
  eta = props.time;
  const classes = useStyles();
  const [draw, toggleDraw] = useState(false);

  const [friends, setFriends] = useContext(FriendContext);
  const [journey, updateJourney] = useContext(JourneyContext);

  const fetchData = async () => {
    try {
      let journeyInitial = journey;
      journeyInitial.users = friends.map(
        friend =>
          (friend = { name: friend.name, userID: friend.userID, toggle: false })
      );
      journeyInitial.eta = eta;
      await updateJourney(journeyInitial);
    } catch (error) {
        console.log("ERROR Loading friends");
      throw error;
    }
  };

  useEffect(() => {
    fetchData();
    toggleDraw(!draw);
  }, []);

  const style = {
    background: '#00c7ac',
    color: 'white',
  };
  
  const toolbarStyle = {
    background: 'white',
    paddingBottom: "1vh",
    paddingTop: "1vh",
  };
  
  async function toggleUser(index) {
    ReactGA.event({
      category: "Social",
      action: "Toggle Journey sharing with a friend",
    });
    let update = journey;
    update.users[index].toggle = !update.users[index].toggle;
    await updateJourney(update);
    toggleDraw(!draw);
  }

  function handleClose() {
    toggleOpen(!open);
  }

  function startJourney() {
    var userData = journey.users
      .filter(e => e.toggle === true)
      .map(e => e.userID);
    //don't send to server if no notifications
    if (userData.length === 0 ) {
      handleClose();
      toggleNavigating(true);
      props.closeAll();
      return;
    }
    var sendData = { users: userData, eta: journey.eta };
    JourneyNotify(sendData);
    updateJourney(sendData);
    handleClose();
    toggleNavigating(true);
    props.closeAll();
  }

  return (
    <>
      <AppBar className={classes.appBar} position="sticky">
        <Toolbar style={toolbarStyle}>
          <Grid container 
          direction="row"
          justifyContent="flex-start"
          >
            <Grid item xs={1}>
              <IconButton
                edge="start"
                onClick={handleClose}
                aria-label="close"
                style={{paddingLeft: 0, paddingRight: 1}}
              >
                <img src={CloseIcon} width="20rem" height="20rem"></img>
              </IconButton>
            </Grid>
            <Grid item xs={10}>
              <Typography
                // className={classes.typography.body1}
                variant="subtitle1"
                color="textPrimary"
                align="left"
              >
                Select friends to notify
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
              >
                Automatically tell them you’ve left, when you are due to arrive,
                and when you have arrived!
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid container className="notifyee-container" justifyContent="center">
        <Grid item xs={12}>
        <div style={{marginTop: 10, marginBottom: 10}}>
          <Paper align="left">
          <div style={{marginLeft: 10}}>
            <Typography
              className={classes.title}
              variant="subtitle1"
              color="primary"
            >
              My Friends
            </Typography>
            </div>
            <List className="FriendTextList">
              {journey.users === undefined
                ? null
                : journey.users.map((user, i) => {
                    const labelId = `user-no-${i}`;
                    return (
                      <ListItem
                        key={i}
                        role={undefined}
                        dense
                        button
                        onClick={() => toggleUser(i)}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={user.toggle}
                            color="primary"
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={user.name} />
                      </ListItem>
                    );
                  })}
            </List>
          </Paper>
          </div>
        </Grid>
        <Grid item xs={8}>
          <div style={{marginTop: 10, marginBottom: 10}}>
            <Button size="large" fullWidth={true} variant="contained" style={style} onClick={startJourney}>
              <Typography variant="subtitle1" >
                START JOURNEY
              </Typography>
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
