import React, { useState, useEffect } from "react";
import { AddFeedback } from "../data_exchange/Feedback_Data";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import ReactGA from "react-ga4";
import exit_icon from "../../media/MyWay__close_icon.svg";
import { Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid, 
  IconButton,
  Typography, 
  TextField } from "@mui/material";

const bigButtonStyle = {
  background: "#00c7ac",
  color: "white",
};

const useStyles = makeStyles(theme => ({
  modal: {
    width: "80%",
    padding: theme.spacing(2, 4, 3),
    backgroundColor: theme.palette.background.paper
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  }
}));

const theme = createTheme({
  palette: {
    text: {
      primary: '#2D2D68',
      secondary: '#3f3f3f',
      disabled: '#d5d5db',
      hint: '#9d9da5'
    },
    typography: {
      body1: {
        fontSize: "1rem",
      },
      body2: {
        fontSize: "0.875rem",
      },
      button:{
        fontSize: "0.875rem",
      }
    }
  }
});
  

export default function NewFeedback(props) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [state, setState] =useState({
    description: "",
    status:"New",
    });

    useEffect(() => {
        if(props.open){
          ReactGA.send({hitType: "pageview",page: "/new_feedback", title:"New Feedback"});
        }
    }, []);


    const handleDescriptionChange = e => {
        setState({ ...state, description: e.target.value });
    };

    const cancelForm=() => {
        setState({ ...state, description: "", status: ""});
        props.parentCallback(); 
    }

    const sendFeedback = async () => {
      const myFeedback={
            description: state.description,
            status: state.status,
        }
        // Update the database
        await AddFeedback(myFeedback);
        setState({ ...state, description: "",status: ""});

        // Close the form and let the parent know
        props.parentCallback(); 
    }

    return (
        <ThemeProvider theme={theme}>
            <Dialog
                fullWidth={true}
                aria-labelledby="new-feedback-dialog"
                aria-describedby="allows a user to add new feedback and suggested improvements"
                open={props.open}
                onClose={cancelForm}
                scroll="paper"
                closeAfterTransition
            >
              <DialogTitle id="form-dialog-title" color="textSecondary">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={11} style={{ textAlign: "center" }}>
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      style={{ textAlign: "center" }}
                    >
                    Help us improve MyWay
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      edge="start"
                      color="primary"
                      style={{ position: "relative", top: -2, right: 0 }}
                      onClick={cancelForm}
                      aria-label="close"
                    >
                      <img
                        src={exit_icon}
                        color="primary"
                        width="20rem"
                        height="20rem"
                      ></img>
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="textSecondary">
                                Please tell us how we can make MyWay work better for you
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                autofocus
                                id="description" 
                                variant="filled" 
                                placeholder="" 
                                fullWidth 
                                multiline
                                rows="5" 
                                onChange={handleDescriptionChange}
                                inputProps={{    style: { fontSize: 16, background: "#F4F4F6" },  }}               
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" fullWidth onClick={sendFeedback} style={bigButtonStyle} color="primary">
                        Send Feedback
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};
