import React, {useState, useContext} from 'react';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import FriendsIcon from '@mui/icons-material/People';
import ReportsIcon from '@mui/icons-material/Report';
import FiltersIcon from '@mui/icons-material/FilterAlt';
import FeedbackIcon from '@mui/icons-material/Comment';
import HomeAddressIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import ShowReports from '../ui_elements/ShowReports';
import AdjustFilters from './AdjustFilters';
import ShowBystander from '../ui_elements/ShowBystander';
import ShowFeedback from '../ui_elements/ShowFeedback';
import SetHome from '../ui_elements/SetHome';
import ManageFriends from './ManageFriends';
import { AuthContext } from "../authentication/AuthContext";
import ReactGA from 'react-ga4';

const gClientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const serverURL = process.env.REACT_APP_SERVER_URL;
const serverPort = process.env.REACT_APP_SERVER_PORT;

export default function BottomDrawer(props) {
  const [open, setOpen] = useState(false);
  const [openFriends, setOpenFriends] = useState(false);
  const [openReports, setOpenReports] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [openBystander, setOpenBystander] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openHomeAddress, setOpenHomeAddress] = useState(false);
  const [selectedItem,setSelectedItem] = useState("empty");
  const [login, setLogin] = useContext(AuthContext);
  const history = useHistory();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    };

    setOpen(open);
    props.parentCallback(!open,selectedItem); 
  };

  const handleSelectedItem =(selection) => {
    //e.preventDefault();
    switch(selection) {
      case "friends":
        setOpenFriends(true);
        break;
      case "reports":
        setOpenReports(true);
        break;
      case "filters":
        setOpenFilters(true);
        break;
      case "bystander":
        setOpenBystander(true);
        break;
      case "feedback":
        setOpenFeedback(true);
        break;
      case "homeAddress":
        setOpenHomeAddress(true);
        break;
      case "logOut":
        logOut();
      break;
      default:              
    }
  }

  function handleClose() {
    setOpenFriends(false);
    setOpenReports(false);
    setOpenFilters(false);
    setOpenBystander(false);
    setOpenFeedback(false);
    setOpenHomeAddress(false);
  };

  async function logOut() {
    const response = await fetch(serverURL+serverPort+"/signout", {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    ReactGA.event({
      category: "User",
      action: "Logged out",
    });
    localStorage.clear();
    setLogin(false);
    history.push("/signin");
  }

  const list = () => (
    <div>
    <Box
      sx={{ width: 'auto' }}
      role="presentation"
    >
      <List style={{marginLeft: "15%"}} >
        <ListItem key='friends_mi' disablePadding>
          <ListItemButton onClick={() =>handleSelectedItem("friends")}>
            <ListItemIcon>
              <FriendsIcon />
            </ListItemIcon>
            <ListItemText primary='Friends' />
          </ListItemButton>
        </ListItem>
        <ListItem  key='reports_mi' disablePadding>
          <ListItemButton onClick={() =>handleSelectedItem("reports")}>
            <ListItemIcon>
              <ReportsIcon />
            </ListItemIcon>
            <ListItemText primary='Reported incidents'/>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List style={{marginLeft: "15%"}}>
        <ListItem  key='set_home_mi' disablePadding>
          <ListItemButton onClick={() =>handleSelectedItem("homeAddress")}>
            <ListItemIcon>
              <HomeAddressIcon />
            </ListItemIcon>
            <ListItemText primary='Set home address' />
          </ListItemButton>
        </ListItem>
        <ListItem  key='filters_mi' disablePadding>
          <ListItemButton onClick={() =>handleSelectedItem("filters")}>
            <ListItemIcon>
              <FiltersIcon />
            </ListItemIcon>
            <ListItemText primary='Adjust map filters' />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List style={{marginLeft: "15%"}}>
        <ListItem  key='bystander_mi' disablePadding>
          <ListItemButton onClick={() =>handleSelectedItem("bystander")}>
            <ListItemIcon>
              <VolunteerActivismIcon />
            </ListItemIcon>
            <ListItemText primary='Active bystander guidance' />
          </ListItemButton>
        </ListItem>
        <ListItem  key='feedback_mi' disablePadding>
          <ListItemButton onClick={() =>handleSelectedItem("feedback")}>
            <ListItemIcon>
              <FeedbackIcon />
            </ListItemIcon>
            <ListItemText primary='Help improve MyWay' />
          </ListItemButton>
        </ListItem>
        <ListItem  key='logout_mi' disablePadding>
          <ListItemButton onClick={() =>handleSelectedItem("logOut")}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary='Log out' />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>

    </div>
  );

   return (
    <div>
      <IconButton onClick={toggleDrawer(true)} style={{marginTop:10, marginBottom:15}} ><MenuIcon fontSize='large'/></IconButton>
      <Drawer
        anchor='bottom'
        open={open}
        onClose={toggleDrawer(false)}
      >
        {list()}
      </Drawer>
      <ManageFriends parentCallback={handleClose} open={openFriends} />
      <ShowReports parentCallback={handleClose} open={openReports} update={props.newReport} load="all reports"/>
      <AdjustFilters parentCallback={handleClose} open={openFilters} />
      <ShowBystander parentCallback={handleClose} open={openBystander} />
      <ShowFeedback parentCallback={handleClose} open={openFeedback} />
      <SetHome parentCallback={handleClose} open={openHomeAddress} />
    </div>
  );
}

