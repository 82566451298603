import React, { useState, useContext } from "react";
import GoogleLogin from "react-google-login";
import { useHistory } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { isLoggedIn, formatPayload } from "./Utils";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { ThemeProvider } from "@material-ui/core/styles";
import themeSignUpIn from "../themeSignUpIn";
import ReactGA from 'react-ga4';

//google login key
const gClientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const serverURL = process.env.REACT_APP_SERVER_URL;
const serverPort = process.env.REACT_APP_SERVER_PORT;
var logInStatus = false;
var snackBar = null;

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "7vh",
  },
  submit: {
    margin: theme.spacing(1, 0, 3)
  },
  whiteText: {
    color: '#ffffff'
  },
  whiteButton: {
    color: '#ffffff',
    margin: theme.spacing(1,0,3)
  }
}));

function SignUp(props) {
  ReactGA.send({hitType: "pageview",page: "/signup", title:"Sign Up"});

  snackBar = props.enqueueSnackbar;
  //state for the form data
  const [data, setData] = useState({
    provider: "local",
    email: "",
    firstname: "",
    lastname: "",
    password: "",
    phone: ""
  });

  const [login, setLogin] = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  async function validatePhone(phone) {
    // we are going to strip other decoration - spaces, brackets, dashes
    var number = phone.replace(/\s|\(|\)|\-|\./g, "").toString();
    // we are going to assume a UK phone unless otherwise specified by user by adding +nn
    if (number.charAt(0)==='0') {
      number="+44"+number.substring(1);
    } else if (number.charAt(0)!=='+') {
      snackBar("Please correct phone number format", {
        variant: "error"
      });
      return false;
    }
    const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
    const PNF = require("google-libphonenumber").PhoneNumberFormat;
    try {
      number = phoneUtil.parseAndKeepRawInput(number);
      var valid = phoneUtil.isValidNumber(number);
      if (valid) {
        number = phoneUtil.format(number, PNF.E164);
        //make sure to set state with the correctly formatted number
        let tempData = data;
        tempData.phone = number;
        await setData(tempData);
        return true;
      } else {
        snackBar("Not a valid phone number", {
          variant: "error"
        });
        return false};
    } catch {
      snackBar("Not a valid phone number - try including international code", {
        variant: "error"
      });
      return false;
    }
  }

  function googleAddPhone(e) {
    setData({
      provider: "google",
      email: e.profileObj.email,
      firstname: e.profileObj.givenName,
      lastname: e.profileObj.familyName,
      phone: ""
    });
    setOpen(true);
  }

  // signing up with google OAuth
  async function submitGoogle() {
    var phone = document.getElementById("phone_number_input").value;
    if (phone === "") return;
    var valid = await validatePhone(phone);
    if (!valid) return;

    try {
      const formBody = formatPayload(data);
      // console.log("Trying to sign up with Google", formBody);
      const response = await fetch(serverURL+serverPort+"/signup", {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: formBody
      });
      if (response.status === 200) {
        logInStatus = isLoggedIn(login);
        snackBar("Signup successful, you can login with Google now", { variant: "success" });
        ReactGA.event({
          category: 'User',
          action: 'Created an Account via Google'
        });
        history.push("/");
        setData("");
      }
    } catch {
      snackBar("Error encountered, try again", { variant: "error" });
    }
  }

  const errorGoogle = e => {
    snackBar("Google Sign Up encountered error, try again", {
      variant: "error",
    });
  };

  //update the state of a field in the form data
  const onChange = e => {
    setData({
      ...data,
      [e.target.name]: (e.target.value).replace(/\s/g, "").toString()
    });
  };

  //method for handling sign up with the email/pass form
  const handleSubmit = async e => {
    e.preventDefault();
    var phone = document.getElementById("phone_number_input_form").value;
    var valid = await validatePhone(phone);
    if (!valid) return;

    try {
      const formBody = formatPayload(data);
      const response = await fetch(serverURL+serverPort+"/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: formBody
      });
      if (response.status===409) {
        snackBar("Email address is already in use", { variant: "error" });
      } else if (response.ok) {
        setVisible(true);
        snackBar("Success, verify the email address", { variant: "success" });
      }
    } catch {
      snackBar("Error encountered, try again", { variant: "error" });
    }
  };

  //method to verify the email used and finish local signup
  const handleVerify = async e => {
    e.preventDefault();
    var PIN = document.getElementById("verification_PIN_field").value;
    PIN = PIN.replace(/\s/g, "").toString();
    if (PIN === "") return;
    const formBody = formatPayload({ email: data.email, pin: PIN });
    try{
      const response = await fetch(
        serverURL+serverPort+"/signupvalidate",
        {
          method: "POST",
          mode: "cors",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body: formBody
        }
      );
      if (response.status === 200) {
        ReactGA.event({
          category: 'User',
          action: 'Created an Account via Email'
        });
        setVisible(false);
        logInStatus = isLoggedIn(login);
        snackBar("Signup successful", { variant: "success" });
        history.push("/");
        setData("");
      }
    }
    catch {
      snackBar("Error encountered, email and PIN don't match or offline", { variant: "error" });
    }
  };

  function ValidateEmail() {
    if(visible){
      ReactGA.send({hitType: "modalview",page: "/signup_validate_email", title:"Sign Up Email Validation"});
    }
    return (
      <div className="FormField">
        <Dialog
          open={visible}
          onClose={() => setVisible(false)}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style:{background: `linear-gradient(rgba(00,199,172,1),transparent)`, backgroundColor: '#2d2d68' }
          }}
        >
          <DialogTitle id="form-dialog-title" color="textSecondary">Sign Up</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the security code you received in the email from
              myway@thingitude-apps.com. Try checking spam folder if you haven't
              received the code.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="verification_PIN_field"
              placeholder="Verification pin"
              label="Verification Pin"
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleVerify}
              color="primary"
              fullWidth
              variant="contained"
            >
              Finish Sign Up
            </Button>
            <Button
              className="FormField__Button mr-20"
              onClick={() => setVisible(false)}
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  function AddPhone() {
    if(open){
      ReactGA.send({hitType: "modalview",page: "/signup_add_phone", title:"Sign Up Add Phone"});
    }
    return (
      <div className="FormField">
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
          PaperProps={{
            style:{background: `linear-gradient(rgba(00,199,172,1),transparent)`, backgroundColor: '#2d2d68' }
          }}

        >
          <DialogTitle id="form-dialog-title">Sign Up</DialogTitle>
          <DialogContent>
            <TextField
              type="tel"
              id="phone_number_input"
              label="Phone number"
              variant="outlined"
              name="phone"
              className="FormField__Input"
              helperText="Enter your phone number with country code e.g: +447xxxxxxxxx"
              title="Phone Number with country code e.g : +447xxxxxxxxx"
              placeholder="+447xxxxxxxxx"
              maxLength="15"
              // value={data.phone}
              // onChange={onChange}
              // onChange={e => {
              //   data(e.target.value);
              // }}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={submitGoogle}
              color="primary"
              fullWidth
              variant="contained"
            >
              Finish Signup
            </Button>
            <Button
              className="FormField__Button mr-20"
              onClick={e => setVisible(false)}
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  function verification(){
    if (document.getElementById("email_form_input").value === "") {
      snackBar("No email found");
      return;
    }
    else setVisible(true);
  }

  return (
    <ThemeProvider theme={themeSignUpIn}>
      <Container component="main" maxWidth="xs" className="container" style={{background: `linear-gradient(rgba(00,199,172,1),transparent)`, backgroundColor: '#2d2d68' }}>
        <Grid
          container
          alignItems="center"
        >
          <form
            onSubmit={handleSubmit}
            className={classes.form}
            autoComplete="off"
          >
            <Grid
              container
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              {/* FIRSTNAME FIELD */}
              <Grid item xs={6} align="center">
                <TextField
                  fullWidth
                  type="text"
                  label="First name"
                  name="firstname"
                  id="firstname"
                  data={data.firstname}
                  onChange={onChange}
                  required
                  inputProps={{type:'text', required: true}}
                />
              </Grid>
              {/* LASTNAME FIELD */}
              <Grid item xs={6} align="center">
                <TextField
                  fullWidth
                  type="text"
                  id="lastname"
                  label="Last name"
                  name="lastname"
                  data={data.lastname}
                  onChange={onChange}
                  required
                  inputProps={{type:'text', required: true}}
                />
              </Grid>
              {/* EMAIL FIELD */}
              <Grid item xs={12} align="center">
                <TextField
                  fullWidth
                  type="email"
                  label="Email"
                  name="email"
                  id="email_form_input"
                  data={data.email}
                  onChange={onChange}
                  required
                  inputProps={{type:'email', required: true}}
                />
              </Grid>
              {/* PASSWORD FIELD */}
              <Grid item xs={12} align="center">
                <TextField
                  fullWidth
                  type="password"
                  name="password"
                  label="Password"
                  id="password"
                  minLength="8"
                  data={data.password}
                  onChange={onChange}
                  required
                  inputProps={{type:'password', required: true}}
                />
              </Grid>
              {/* PHONE NUMBER FIELD */}
              <Grid item xs={12} align="center">
                <TextField
                  fullWidth
                  type="tel"
                  id="phone_number_input_form"
                  label="Phone number"
                  name="phone"
                  title="Phone Number"
                  placeholder="07xxxxxxxxx"
                  maxLength="15"
                  data={data.phone}
                  onChange={onChange}
                  required
                />
              </Grid>
              {/* SIGNUP BUTTON*/}
              <Grid item xs={12} align="center">
                <Button
                  className={classes.submit}
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{"marginBottom":0}}
                >
                  <Typography variant="h6" color="textPrimary">
                    SIGN UP
                  </Typography>
                </Button>
                <Typography variant="body1" color="textPrimary" style={{"margin":8}}>
                  OR
                </Typography>
                <GoogleLogin
                  clientId={gClientID}
                  buttonText="Sign Up with Google"
                  onSuccess={googleAddPhone}
                  onFailure={errorGoogle}
                  cookiePolicy={"single_host_origin"}
                />
              </Grid>
            </Grid>
          </form>
          {/* SIGNIN LINK */}
          <Grid item xs={12} align="center" style={{"marginTop":"7vh"}}>
            <Typography variant="body1" color="textPrimary">
              Already have an account?
            </Typography>
            <Button fullWidth href="/signin" variant="outlined"  color="primary" className={classes.whiteButton}>
              Sign In
            </Button>
          </Grid>
          <Grid item xs={12} align="center">
            <Typography variant="body1" color="textPrimary">
              Received a verification PIN?
            </Typography>
            <Button fullWidth variant="outlined"  color="primary" className={classes.whiteButton} onClick={verification}>
              Enter PIN
            </Button>
            <Button
              fullWidth
              href="/privacy_policy"
              color="primary"
              className={classes.whiteButton}
            >
              Privacy Policy
            </Button>
          </Grid>
        </Grid>
        <ValidateEmail />
        <AddPhone />
      </Container>
    </ThemeProvider>
  );
}

/*
                <Typography variant="body1" color="textPrimary" style={{"margin":8}}>
                  OR
                </Typography>
                <GoogleLogin
                  clientId={gClientID}
                  buttonText="Sign Up with Google"
                  onSuccess={googleAddPhone}
                  onFailure={errorGoogle}
                  cookiePolicy={"single_host_origin"}
                />
*/

export default withSnackbar(SignUp);
