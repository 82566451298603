import React, {useContext, useState, useEffect} from "react";
import { LocationSearchInput } from "../Directions";
import {PlaceContext} from "../MarkerContext";
import {UpdateHome} from "../data_exchange/User_Data";
import { withSnackbar } from 'notistack';
import {geocodeByAddress, getLatLng} from "react-places-autocomplete";
import exit_icon from "../../media/MyWay__close_icon.svg";
import { Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid, 
    IconButton,
    Typography } from "@mui/material";
var snackBar;

function SetHome(props) {
  snackBar = props.enqueueSnackbar;
  const [places, updatePlaces] = useContext(PlaceContext);
  const bigButtonStyle = {
    background: "#00c7ac",
    color: "white",
  };

  async function setHome(){
    var address = document.getElementById("setting_home_address");
    var coords = null;
    if (address && address.value) {
        address = address.value;
        await geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(latLng => coords = latLng)
        .catch(error => console.error('Error', error));
      } else {
        snackBar("Enter a valid address", {variant: 'error'});
        return;
      }
    updatePlaces([{name:"Home", location: address}]);
    // localStorage.setItem("Home", JSON.stringify(address));
    
    try{
      var response = await UpdateHome({longitude: coords.lng, latitude: coords.lat});
      if (response.ok){
        snackBar("Address updated successfully", {variant: 'success'});
      }
    }
    catch{
      snackBar("Address saved for this session, but couldn't connect to the server", {variant: 'error'});
    }
    
    props.parentCallback();
  }

  return (
      <Dialog
        fullWidth={true}
        open={props.open}
        onClose={props.parentCallback}
        aria-labelledby="set-home-address"
      >
        <DialogTitle id="form-dialog-title" color="textSecondary">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={11} style={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                color="textPrimary"
                style={{ textAlign: "center" }}
              >
                Where's home?
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                edge="start"
                color="primary"
                style={{ position: "relative", top: -2, right: 0 }}
                onClick={props.parentCallback}
                aria-label="close"
              >
                <img
                  src={exit_icon}
                  color="primary"
                  width="20rem"
                  height="20rem"
                ></img>
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Set up your home address for the Take Me Home button
          </DialogContentText>
          <LocationSearchInput
            idOfInput="setting_home_address"
            text={"Home Address?"}
          ></LocationSearchInput>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={setHome}
            color="primary"
            fullWidth
            style={bigButtonStyle}
            variant="contained"
          >
            Add Home
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default withSnackbar(SetHome);