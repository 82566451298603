import React, { useContext, useState, useEffect } from "react";
import {SearchContext} from "./UIContext";
import { Marker } from "google-maps-react";
import {
  Grid,
  Paper,
  Button,
  Dialog,
  makeStyles,
  IconButton,
  Backdrop,
  Typography,
  Card,
  CardContent,
  CardActionArea,
} from "@material-ui/core";
import ReactGA from 'react-ga4';

export default function RecentSearches(props) {
const func = props.callback
const [search, updateSearch] = useContext(SearchContext);

// just return the id since direction serach can do everything else
function handleClick(id){
  ReactGA.event({
    category: "Directions",
    action: "Use a recent search",
  });
    func(id);
}

  return (
    <Grid container justifyContent="center">
        <Grid item xs={12}>
        <Typography variant="h6" color="primary" style={{marginTop: 10}}>
                    Recent
                  </Typography>
        </Grid>
      {search === undefined
        ? null
        : search.map((result, i) => {
            return (
              <Grid item xs={12} key={i}>
                <Card style={{ margin: 1 }} onClick={() => handleClick(i)}>
                  <CardActionArea>
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" color="textPrimary">
                            {result.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2" color="textSecondary">
                            {result.location}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
    </Grid>
  );
}
