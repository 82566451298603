import React, { useState, createContext } from "react";

export const UIContext = createContext([{}, function() {}]);
export const UIContextProvider = props => {
  const [ui, updateUI] = useState([
    {
      refreshFriends: true
    }
  ]);

  return (
    <UIContext.Provider value={[ui, updateUI]}>
      {props.children}
    </UIContext.Provider>
  );
};


export const SearchContext = createContext([{}, function() {}]);
export const SearchContextProvider = props => {
  const [search, updateSearch] = useState([
  ]);

  return (
    <SearchContext.Provider value={[search, updateSearch]}>
      {props.children}
    </SearchContext.Provider>
  );
};

