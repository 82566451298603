import React, { useContext, useEffect } from "react";
import { UserContext } from "../MarkerContext";
import { LocationUpdateTimer } from "../data_exchange/User_Data";

var googleProp;
var mapProp;
var mapCenterProp;
var userPos;
var marker;
var animationReq;
var deltalat;
var deltalng;
var latlng;
var watchPos;
var updateLOC;

function animatedMove(marker, n, current, moveTo) {
  if (googleProp != undefined) {
    if (n < 30) {
      var lat = current.lat();
      var lng = current.lng();
      lat += deltalat;
      lng += deltalng;
      latlng = new googleProp.maps.LatLng(lat, lng);
      marker.setPosition(latlng);
      animationReq = requestAnimationFrame(() =>
      animatedMove(marker, n+1, latlng, moveTo)
      );
    } else {
      marker.setPosition(moveTo);
      updateLOC(userPos);
      cancelAnimationFrame(animationReq);
    }
  }
}

const UserIcon = (props) => {
  const [user, updateUser] = useContext(UserContext);
  updateLOC = setLocation;
  userPos = props.mapCenter;

  //the props that marker needs
  googleProp = props.google;
  mapProp = props.map;
  mapCenterProp = props.mapCenter;

  // initial draw of the marker
  useEffect(() => {
    getPos();
    setTimeout(() => {
      marker = new googleProp.maps.Marker({
        map: mapProp,
        position: user.position,
        icon: {
          url: user.symbol,
          anchor: new googleProp.maps.Point(14, 14),
          scaledSize: new googleProp.maps.Size(28, 28),
        },
        animation: googleProp.maps.Animation.DROP,
      });
      //console.log("Starting the DB timer");
      LocationUpdateTimer(user);
    }, 1000);
  }, []);

  function setLocation(location){
    let userNew = user;
    userNew.position = location;
    updateUser(userNew);
  }

  async function getPos() {
    let locationOLD;
    userPos = new googleProp.maps.LatLng(mapCenterProp.lat, mapCenterProp.lng);
    setLocation(userPos);
    watchPos = navigator.geolocation.watchPosition(
      (pos) => {
        userPos = new googleProp.maps.LatLng(
          pos.coords.latitude,
          pos.coords.longitude
        );
        if (marker !== undefined && userPos !== undefined) {
          if (JSON.stringify(marker.position) !== JSON.stringify(userPos)) {
              locationOLD = marker.position;
              deltalat = (userPos.lat() - locationOLD.lat()) / 30;
              deltalng = (userPos.lng() - locationOLD.lng()) / 30;
              animationReq = requestAnimationFrame(() =>
              animatedMove(marker, 0, locationOLD, userPos)
              );
          }
        } else {
          if (userPos==undefined) {
            console.log("FAILED TO GET USERPOS");
          } else if (marker==undefined) {
            console.log("FAILED TO GET MARKER");
            setLocation(userPos);
          }
        }
      },
      function error(msg) {
        alert('Please enable your GPS position future.');
      },
      { maximumAge: 0, timeout: Infinity, enableHighAccuracy: true }
    );
  }

  return null;
};

export default UserIcon;
