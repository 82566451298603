import React, { useState, useEffect } from "react";
import {
  RetrieveMyProtoReports,
  RetrieveMyReports,
  IgnoreReport,
} from "../data_exchange/Report_Data";
import Severity from "../ui_elements/Severity";
import UpdateReport from "../ui_elements/UpdateReport";
import exit_icon from "../../media/MyWay__close_icon.svg";

import { Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton, 
  Typography } from "@mui/material";
import * as moment from "moment";

export default function ShowReports(props) {
  const [myReports, setMyReports] = useState([]);
  const [state, setState] = useState({
    reportIndex: 0,
    showUpdateReport: false,
  });

  useEffect(() => {
    loadMyReports();
  }, [props.update]);

  const loadMyReports = async () => {
    var fetchReports = [];
    if (props.load === "protoReports") {
      fetchReports = await RetrieveMyProtoReports();
      //console.log("Retrieving protoReports");
    } else {
      fetchReports = await RetrieveMyReports();
      //console.log("Retrieving reports");
    }
    //prevent errors if unretrieved
    if (fetchReports !== null) {
      //map the returned array to the friend context
      var arrayOfReports = fetchReports.map(
        (report) =>
          (report = {
            reportID: report.incidentid,
            description: report.description,
            severity: report.severity,
            status: report.status,
            position: { lat: report.latitude, lng: report.longitude },
            address: report.address,
            timestamp: report.timestamp,
            lastUpdated: new Date().toUTCString(),
            clickable: true,
            type: "report",
          })
      );
      setMyReports(arrayOfReports);
    } else {
      // There are no reports to see
      setMyReports([]);
      hideReports();
    }
  };

  //removes report from the context
  async function removeReport(index) {
    await IgnoreReport(myReports[index].reportID);
    let newReports = myReports.filter((item) => item !== myReports[index]);
    setMyReports(newReports);
  }

  function editReport(index) {
    setState({ ...state, reportIndex: index, showUpdateReport: true });
  }

  const hideReports = () => {
    props.parentCallback(myReports.length);
  };

  const hideUpdateReports = () => {
    setState({ ...state, reportIndex: 0, showUpdateReport: false });
    loadMyReports();
  };

  return (
    <Dialog
      fullWidth={true}
      aria-labelledby="my-reports-dialog"
      aria-describedby="lists-my-reports-and-lets-user-delete-them"
      open={props.open}
      onClose={hideReports}
      scroll="paper"
      closeAfterTransition
    >
      <DialogTitle id="my-reports" color="textSecondary">
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={11} style={{ textAlign: "center" }}>
            <Typography
              variant="h6"
              color="textPrimary"
              style={{ textAlign: "center" }}
            >
              My reports
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              edge="start"
              color="primary"
              style={{ position: "relative", top: -2, right: 0 }}
              onClick={hideReports}
              aria-label="close"
            >
              <img
                src={exit_icon}
                color="primary"
                width="20rem"
                height="20rem"
              ></img>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {/* Returns empty list if no reports found */}
        {myReports.length === 0 ? (
          <></>
        ) : (
          myReports.map((report, i) => {
            return (
              <Card style={{ margin: 3 }}>
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={1}>
                      <Severity severity={report.severity} />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography
                        variant="body1"
                        component="p"
                        color="textPrimary"
                        display="inline"
                      >
                        {report.description}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={7}>
                      <Typography
                        variant="body2"
                        component="p"
                        color="textSecondary"
                        display="inline"
                      >
                        {report.address}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        size="small"
                        onClick={() => editReport(i)}
                      >
                        Edit
                      </Button>
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        size="small"
                        color="secondary"
                        onClick={() => removeReport(i)}
                      >
                        Remove
                      </Button>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        display="inline"
                      >
                        {moment(report.timestamp).format(
                          " HH:mm    D MMM YYYY"
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          })
        )}
        {myReports !== null ? (
          <UpdateReport
            open={state.showUpdateReport}
            report={myReports[state.reportIndex]}
            parentCallback={hideUpdateReports}
          />
        ) : null}
      </DialogContent>
    </Dialog>
  );
}
