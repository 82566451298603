import { formatPayload } from "./Utils";
import { askForPermissionToReceivePushNotifications } from '../../pushNotification';

var snackBarGlobal;
var response;

export async function UpdatePushNotificationPreferences(data, url, snackBar) {
  console.log("Running UpdatePushNotificationPreferences");
  // Can we enable Push Notifications?
  data.pushtoken = await askForPermissionToReceivePushNotifications()
  
  // Update the server with our push settings
  snackBarGlobal = snackBar;
  var formBody = formatPayload(data);
  try {
    response = await fetch(url, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
    });
    if (response.status === 200) {
      return response;
    } else {
      snackBar("Unable to update push notification settings", {variant: "error",});
      return response;
    }
  } catch {
    data.provider === "google" ?
    errorGoogle() : errorLocal()
    return false;
  }
}

const errorLocal = () => {
  snackBarGlobal(
    "Push notification update error with a local login",
    {variant: "error",}
  );
  return false;
};

export const errorGoogle = () => {
  snackBarGlobal("Push notification update error with a Google login", {
    variant: "error",
  });
  return false;
};

