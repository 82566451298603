import { formatPayload } from "../authentication/Utils";
import ReactGA from "react-ga4";
const serverURL = process.env.REACT_APP_SERVER_URL;
const serverPort = process.env.REACT_APP_SERVER_PORT;

export async function WhoAmI() {
  const response = await fetch(serverURL+serverPort+"/whoami", {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
    .then((data) => {
      return data.json();
    })
    .catch(function (error) {
      return undefined;
    });
  return response;
}

export async function LocationUpdateTimer(user) {
  var previousLocation = user.position;
  const interval = setInterval(async () => {
    if (JSON.stringify(previousLocation) !== JSON.stringify(user.position)) {
      //console.log("The location is being updated");
      if (user.showLocation) {
        previousLocation = user.position;
        const status = UpdateLocation(user.position);
        if (!status) {
          console.log("Location update failed.");
        }
      }
    }
  }, 30000);
  return () => clearInterval(interval);
}

export async function UpdateLocation(coords) {
  var formBody = formatPayload({
    longitude: coords.lng,
    latitude: coords.lat,
  });

  let error = /function|return/.test(formBody);
  if (error) {
    coords = JSON.parse(JSON.stringify(coords));
    formBody = formatPayload({
      longitude: JSON.stringify(coords.lng),
      latitude: JSON.stringify(coords.lat),
    });
  }

  const response = await fetch(serverURL+serverPort+"/location/update", {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  });
  return response.ok;
}

export async function LocationPreference(showLocation) {
  const formBody = formatPayload({ showlocation: showLocation });
  const response = await fetch(serverURL+serverPort+"/togglelocation", {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  });
  ReactGA.event({
    category: "User",
    action: "Toggle Location sharing to " + showLocation,
  });
  return response.ok;
}

export async function SetShowLocationByDefault(defaultSetting) {
  const formBody = formatPayload({ showLocationByDefault: defaultSetting });
  const response = await fetch(
    serverURL+serverPort+"/showlocationbydefault",
    {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
    }
  );
  ReactGA.event({
    category: "User",
    action: "Default location sharing set to " + defaultSetting,
  });
  return response.ok;
}

export async function JourneyNotify(journey) {
  const formBody = formatPayload(journey);
  const response = await fetch(serverURL+serverPort+"/friends/notify", {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  });
  ReactGA.event({
    category: "User",
    action: "Friends were notified about a journey",
  });
  return response.ok;
}

export async function UpdateHome(address) {
  const formBody = formatPayload(address);
  const response = await fetch(serverURL+serverPort+"/homelocation", {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  });
  ReactGA.event({
    category: "User",
    action: "Updated Home Address",
  });
  return response.ok;
}

export async function Preferences() {
  const response = await fetch(serverURL+serverPort+"/preferences", {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
    .then((data) => {
      return data.json();
    })
    .catch(function (error) {
      console.log("Looks like there was a problem: \n", error);
      return undefined;
    });
  return response;
}

export async function UpdatePreferences() {
  let preferences = getLocalPreferences();
  const formBody = formatPayload(preferences);
  const response = await fetch(serverURL+serverPort+"/preferences", {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: formBody,
  });
  return response.ok;
}

export function setLocalPreferences(preferences) {
  //localStorage.setItem("friends", JSON.stringify(preferences.friendPrefs.replace(/^"|"$/g, '')));
  localStorage.setItem("friends", preferences.friendPrefs);
  localStorage.setItem("showPeople", JSON.stringify(preferences.showPeople));
  localStorage.setItem(
    "showLighting",
    JSON.stringify(preferences.showLighting)
  );
  localStorage.setItem("showReports", JSON.stringify(preferences.showReports));
  localStorage.setItem(
    "showReportSeverity",
    preferences.showReportSeverity.replace(/"/g, "")
  );
  localStorage.setItem(
    "showReportsWithin",
    JSON.stringify(preferences.showReportsWithin)
  );
  localStorage.setItem(
    "showLocationByDefault",
    JSON.stringify(preferences.showLocationByDefault)
  );
}

export function getLocalPreferences() {
  let preferences = {
    showPeople: localStorage.getItem("showPeople"),
    showLighting: localStorage.getItem("showLighting"),
    showReports: localStorage.getItem("showReports"),
    showReportSeverity: localStorage.getItem("showReportSeverity"),
    showReportsWithin: localStorage.getItem("showReportsWithin"),
    showLocationByDefault: localStorage.getItem("showLocationByDefault"),
    friendPrefs: localStorage.getItem("friends"),
  };
  return preferences;
}

export default WhoAmI;
