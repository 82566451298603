import React, { useState } from "react";
import filters_image from "../media/MyWay__intro_screen_filters.svg";
import friends_image from "../media/MyWay__intro_screen_friends.svg";
import reports_image from "../media/MyWay__intro_screen_reports.svg";
import myway_logo from "../media/myway_logo_512.png";
import android_install from "../media/android_install.png";
import iphone_install from "../media/iphone_install.png";
import iphone_location from "../media/iphone_location.png";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import LensIcon from "@material-ui/icons/Lens";
import ReactGA from 'react-ga4';
import Slide from "@material-ui/core/Slide";
import { Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import {ThemeProvider} from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import themeSignUpIn from "./themeSignUpIn";


var isIOS;

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "15%",
  },
  card: {
    width: "60%",
    margin: "auto",
  },
  slideButtons: {
    marginTop: theme.spacing(5),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  whiteText: {
    color: "#ffffff",
  },
  whiteButton: {
    color: "#ffffff",
    margin: theme.spacing(1, 0, 3),
  },
}));

export default function Intro() {
  isIOS = window.navigator.userAgent.toLowerCase();
  isIOS = /iphone|ipad|ipod/.test(isIOS);
  

  // // Detects if device is in standalone mode
  // const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

  const [showSlide, setShowSlide] = useState("rdg");
  const [direction, setDirection] = useState("left");
  const classes = useStyles();
  const handleSlide = async (nextSlide, dir) => {
    setShowSlide(nextSlide);
    setDirection(dir);
  };

  class RdgSlide extends React.Component {
    constructor(props) {
      super(props);
      ReactGA.send({hitType: "pageview",page: "/rdg_intro", title:"Intro - Reading"});
    }

    render() {
      if (this.props.show === "rdg") {
        return (
          <div>
            <Grid
              container
              direction="row"
              align="center"
              style={{ marginTop: "10%" }}
            >
              <Grid item xs={12} margin="auto">
                <img src={myway_logo} width="52%" alt="filters image" />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              className={classes.slideButtons}
              style={{ top: "40%" }}
            >
              <Grid item xs={2}>
                <IconButton color="primary" disabled>
                  <ArrowBackIosIcon />
                </IconButton>
              </Grid>
              <Grid item xs={8} align="center">
                <Typography
                  className={classes.whiteText}
                  variant="h6"
                  style={{ marginTop: "10px" }}
                >
                  MYWAY READING
                </Typography>
                <Typography
                  className={classes.whiteText}
                  variant="body1"
                  style={{ marginTop: "20px" }}
                >
                  Warn others about harassment, fights, spikings... {<br />}Choose safer routes when walking {<br />}Help make Reading safer for everyone.
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <IconButton
                  color="primary"
                  onClick={() => this.props.parentCallback("filter", "left")}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={12}
                align="center"
                className={classes.slideButtons}
                style={{ top: "60%" }}
              >
                <LensIcon color="primary" />
                <LensIcon />
                <LensIcon />
                <LensIcon />
                <LensIcon />
                {isIOS ? <LensIcon />  : null}
              </Grid>
            </Grid>
          </div>
        );
      } else return <div></div>;
    }
  }

  class FilterSlide extends React.Component {
    constructor(props) {
      super(props);
      ReactGA.send({hitType: "pageview",page: "/filter_intro", title:"Intro - Filter"});
    }

    render() {
      if (this.props.show === "filter") {
        return (
          <div>
            <Grid
              container
              direction="row"
              align="center"
              style={{ marginTop: "10%" }}
            >
              <Grid item xs={12} margin="auto">
                <img src={filters_image} width="100%" alt="filters image" />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              className={classes.slideButtons}
              style={{ top: "40%" }}
            >
              <Grid item xs={3}>
                <IconButton
                  color="primary"
                  onClick={() => this.props.parentCallback("rdg", "right")}
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Grid>
              <Grid item xs={6} align="center">
                <Typography
                  className={classes.whiteText}
                  variant="h6"
                  style={{ marginTop: "10px" }}
                >
                  FILTER
                </Typography>
                <Typography
                  className={classes.whiteText}
                  variant="body1"
                  style={{ marginTop: "20px" }}
                >
                  Set filters based on{<br />}what you want to avoid during your
                  journey
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                <IconButton
                  color="primary"
                  onClick={() => this.props.parentCallback("friend", "left")}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={12}
                align="center"
                className={classes.slideButtons}
                style={{ top: "60%" }}
              >
                <LensIcon />
                <LensIcon color="primary" />
                <LensIcon />
                <LensIcon />
                <LensIcon />
                {isIOS ? <LensIcon />  : null}
              </Grid>
            </Grid>
          </div>
        );
      } else return <div></div>;
    }
  }

  class FriendSlide extends React.Component {
    constructor(props) {
      super(props);
      ReactGA.send({hitType: "pageview",page: "/friend_intro", title:"Intro - Friend"});
    }

    render() {
      if (this.props.show === "friend") {
        return (
          <div>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ marginTop: "10%" }}
            >
              <Grid item xs={12} margin="auto">
                <img src={friends_image} width="100%" alt="friends image" />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              className={classes.slideButtons}
              style={{ top: "40%" }}
            >
              <Grid item xs={3}>
                <IconButton
                  color="primary"
                  onClick={() => this.props.parentCallback("filter", "right")}
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Grid>
              <Grid item xs={6} align="center">
                <Typography
                  className={classes.whiteText}
                  variant="h6"
                  style={{ marginTop: "10px" }}
                >
                  FRIENDS
                </Typography>
                <Typography
                  className={classes.whiteText}
                  variant="body1"
                  style={{ marginTop: "20px" }}
                >
                  Select the friends you want to automatically notify when you
                  arrive
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                <IconButton
                  color="primary"
                  onClick={() => this.props.parentCallback("report", "left")}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={12}
                align="center"
                className={classes.slideButtons}
                style={{ top: "60%" }}
              >
                <LensIcon />
                <LensIcon />
                <LensIcon color="primary" />
                <LensIcon />
                <LensIcon />
                {isIOS ? <LensIcon />  : null}
              </Grid>
            </Grid>
          </div>
        );
      } else return <div></div>;
    }
  }

  class ReportSlide extends React.Component {
    constructor(props) {
      super(props);
      ReactGA.send({hitType: "pageview",page: "/report_intro", title:"Intro - Report"});
    }

    render() {
      if (this.props.show === "report") {
        return (
          <div>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ marginTop: "10%" }}
            >
              <Grid item xs={12} margin="auto">
                <img src={reports_image} width="100%" alt="reports image" />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              className={classes.slideButtons}
              style={{ top: "40%" }}
            >
              <Grid item xs={3}>
                <IconButton
                  color="primary"
                  onClick={() => this.props.parentCallback("friend", "right")}
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Grid>
              
              <Grid item xs={6} align="center">
                <Typography
                  className={classes.whiteText}
                  variant="h6"
                  style={{ marginTop: "10px" }}
                >
                  REPORT
                </Typography>
                <Typography
                  className={classes.whiteText}
                  variant="body1"
                  style={{ marginTop: "20px" }}
                >
                  Pin a location during your journey and report later to warn
                  others
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                <IconButton
                  color="primary"
                  onClick={() => this.props.parentCallback("install", "left")}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={12}
                align="center"
                className={classes.slideButtons}
                style={{ top: "60%" }}
              >
                <LensIcon />
                <LensIcon />
                <LensIcon />
                <LensIcon color="primary" />
                <LensIcon />
                {isIOS ? <LensIcon />  : null}
              </Grid>
            </Grid>
            {/* <Grid
              container
              direction="row"
              style={{ position: "fixed", bottom: "3%" }}
            >
              <Grid item xs={11} align="center">
                <Button
                  variant="outlined"
                  fullWidth
                  className={classes.whiteButton}
                  color="primary"
                  size="large"
                  type="submit"
                >
                  <Typography className={classes.whiteText} variant="subtitle1">
                    Get started
                  </Typography>
                </Button>
              </Grid>
            </Grid> */}
          </div>
        );
      } else return <div></div>;
    }
  }

  class InstallSlide extends React.Component {
    constructor(props) {
      super(props);
      ReactGA.send({hitType: "pageview",page: "/install_intro", title:"Intro - Install"});
    }

    render() {
      if (this.props.show === "install") {
        return (
          <div>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ marginTop: "10%" }}
            >
              <Grid item xs={12} margin="auto" align="center">
                {isIOS ? 
                    <img src={iphone_install} width="80%" alt="add MyWay to your home screen screenshot" /> :
                    <img src={android_install} width="80%" alt="add MyWay to your home screen screenshot" />}
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              className={classes.slideButtons}
              style={{ top: "40%" }}
            >
              <Grid item xs={3}>
                <IconButton
                  color="primary"
                  onClick={() => this.props.parentCallback("report", "right")}
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Grid>
              <Grid item xs={6} align="center">
                <Typography
                  className={classes.whiteText}
                  variant="h6"
                  style={{ marginTop: "10px" }}
                >
                  INSTALL
                </Typography>
                <Typography
                  className={classes.whiteText}
                  variant="body1"
                  style={{ marginTop: "20px" }}
                >
                    {isIOS ? 
                  "Add MyWay to your home screen by pressing the Action/Share icon and selecting from the menu" : "Simply add MyWay to your home screen from the Chrome menu"}
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                {isIOS ?
                  <IconButton
                    color="primary"
                    onClick={() => this.props.parentCallback("install2", "left")}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                : 
                  <IconButton color="primary" disabled>
                    <ArrowForwardIosIcon />
                  </IconButton>
                }
              </Grid>
              <Grid
                item
                xs={12}
                align="center"
                className={classes.slideButtons}
                style={{ top: "60%" }}
              >
                <LensIcon />
                <LensIcon />
                <LensIcon />
                <LensIcon />
                <LensIcon color="primary" />
                {isIOS ? <LensIcon />  : null}
              </Grid>
            </Grid>
            {/* Button to signup - if on Android */}
            {isIOS ? null: 
              <Grid
                container
                direction="row"
                style={{ position: "fixed", bottom: "3%" }}
              >
                <Grid item xs={11} align="center">
                  <Button
                    variant="outlined"
                    fullWidth
                    className={classes.whiteButton}
                    color="primary"
                    size="large"
                    type="submit"
                  >
                    <Typography className={classes.whiteText} variant="subtitle1">
                      Get started
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            }
          </div>
        );
      } else return <div></div>;
    }
  }

  class Install2Slide extends React.Component {
    constructor(props) {
      super(props);
      ReactGA.send({hitType: "pageview",page: "/install2_intro", title:"Intro - Install 2"});
    }

    render() {
      if (this.props.show === "install2") {
        return (
          <div>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ marginTop: "10%" }}
            >
              <Grid item xs={12} margin="auto" align="center">
                <img src={iphone_location} width="80%" alt="enable Location sharing for MyWay" /> 
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              className={classes.slideButtons}
              style={{ top: "40%" }}
            >
              <Grid item xs={3}>
                <IconButton
                  color="primary"
                  onClick={() => this.props.parentCallback("install", "right")}
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Grid>
              <Grid item xs={6} align="center">
                <Typography
                  className={classes.whiteText}
                  variant="h6"
                  style={{ marginTop: "10px" }}
                >
                  INSTALL (cont'd)
                </Typography>
                <Typography
                  className={classes.whiteText}
                  variant="body1"
                  style={{ marginTop: "20px" }}
                >
                  Remember to enable location sharing in the website settings
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                <IconButton color="primary" disabled>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={12}
                align="center"
                className={classes.slideButtons}
                style={{ top: "60%" }}
              >
                <LensIcon />
                <LensIcon />
                <LensIcon />
                <LensIcon />
                <LensIcon />
                <LensIcon color="primary" />
              </Grid>
            </Grid>
            {/* Button to signup */}
            <Grid
              container
              direction="row"
              style={{ position: "fixed", bottom: "3%" }}
            >
              <Grid item xs={11} align="center">
                <Button
                  variant="outlined"
                  fullWidth
                  className={classes.whiteButton}
                  color="primary"
                  size="large"
                  type="submit"
                >
                  <Typography className={classes.whiteText} variant="subtitle1">
                    Get started
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </div>
        );
      } else return <div></div>;
    }
  }


  return (
  <ThemeProvider theme={themeSignUpIn}>
    <Container
      component="main"
      maxWidth="xs"
      className="container"
      style={{
        background: `linear-gradient(rgba(00,199,172,1),transparent)`,
        backgroundColor: "#2d2d68",
        display: "flex",
      }}
    >
      <form
        action="/signup"
        method="get"
        autoComplete="off"
        className={classes.form}
      >
        <p></p>
        <Slide
          direction={direction}
          in={showSlide === "rdg" ? true : false}
          mountOnEnter
          unmountOnExit
        >
          <RdgSlide show={showSlide} parentCallback={handleSlide} />
        </Slide>
        <Slide
          direction={direction}
          in={showSlide === "filter" ? true : false}
          mountOnEnter
          unmountOnExit
        >
          <FilterSlide show={showSlide} parentCallback={handleSlide} />
        </Slide>
        <Slide
          direction={direction}
          in={showSlide === "friend" ? true : false}
          mountOnEnter
          unmountOnExit
        >
          <FriendSlide show={showSlide} parentCallback={handleSlide} />
        </Slide>
        <Slide
          direction={direction}
          in={showSlide === "report" ? true : false}
          mountOnEnter
          unmountOnExit
        >
          <ReportSlide show={showSlide} parentCallback={handleSlide} />
        </Slide>
        <Slide
          direction={direction}
          in={showSlide === "install" ? true : false}
          mountOnEnter
          unmountOnExit
        >
          <InstallSlide show={showSlide} parentCallback={handleSlide} />
        </Slide>
        <Slide
          direction={direction}
          in={showSlide === "install2" ? true : false}
          mountOnEnter
          unmountOnExit
        >
          <Install2Slide show={showSlide} parentCallback={handleSlide} />
        </Slide>
      </form>
    </Container>
    </ThemeProvider>
  );
}
