import { formatPayload } from "../authentication/Utils";
import ReactGA from 'react-ga4';
const serverURL = process.env.REACT_APP_SERVER_URL;
const serverPort = process.env.REACT_APP_SERVER_PORT;

export async function RetrieveFriends() {
  const response = await fetch(serverURL+serverPort+"/friends", {
    method: "GET",
    mode: "cors",
    credentials: "include"
  })
    .then(data => {
      return data.json();
    })
    .catch(function(error) {
      return null;
    });
  return response;
}

export async function AddFriend(url) {
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
  ReactGA.event({
    category: "Social",
    action: "Add a Friend",
  });
  return response.ok;
}

export async function RemoveFriend(friendID) {
  const response = await fetch(
    serverURL+serverPort+`/friends/delete/${friendID}`,
    {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }
  );
  ReactGA.event({
    category: "Social",
    action: "Remove a Friend",
  });
  return response.ok;
}

