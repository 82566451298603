import React, { useContext, useState, useEffect, memo } from "react";
import { BusContext, UserContext } from "../MarkerContext";
import { Marker } from "google-maps-react";

var markerArray;
var currentZoom = 16;

function BusMarkers(props) {
  const [buses, setBuses] = useContext(BusContext);

  //the props that every marker needs
  const googleProp = props.google;
  const mapProp = props.map;
  const mapCenterProp = props.mapCenter;
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (mapProp === undefined) {
      return;
    }
    markerArray = Markers();
    // markerArray = memo(Markers());
    // limitDraw();
  });

  function limitDraw() {
    googleProp.maps.event.addListener(mapProp, "zoom_changed", function () {
      var zoom = mapProp.getZoom();
      console.log("zoom level:", zoom, "visibility:", visible);
      if (zoom <= 15 && zoom !== currentZoom) {
        setVisible(!visible);
        currentZoom = zoom;
        // markerArray = null;
	    	// markerArray = markerArray.map((marker) => {
        //     marker.visible(false);
        // console.log("THIS")
        // }
        // );
      }
    });
  }

  const Markers = () => {
    let empty = true;
    if (buses !== undefined) {
      if (buses.length > 0) {
        empty = false;
      }
    }

    return empty
      ? null
      : buses.map((bus, i) => (
          <Marker
            visible={visible}
            key={i}
            id={bus.id}
            name={bus.name}
            position={bus.position}
            map={mapProp}
            google={googleProp}
            mapCenter={mapCenterProp}
            icon={{
              url: bus.symbol,
              anchor: new googleProp.maps.Point(8, 8),
              scaledSize: new googleProp.maps.Size(16, 16),
            }}
            onClick={props.onClick}
            clickable={true}
            type={bus.type}
            lastUpdated={bus.lastUpdated}
          />


        // <div>
        //       new googleProp.maps.Marker({
        //       visible: true,
        //       id: bus.id,
        //       name: bus.name,
        //       position: bus.position,
        //       map: mapProp,
        //       mapCenter: mapCenterProp,
        //       icon:{
        //         url: bus.symbol,
        //         anchor: new googleProp.maps.Point(8, 8),
        //         scaledSize: new googleProp.maps.Size(16, 16),
        //       },
        //       onClick: bus.onClick,
        //       clickable: true,
        //       // type={bus.type}
        //       // lastUpdated={bus.lastUpdated}
        //   })
        //   </div>
        ));
  };
  return (
    <>
      {markerArray === undefined ? null 
    //   : <>{markerArray}</>}
      : markerArray.map((bus) => <>{bus}</>)}
    </>
  );
}

export default BusMarkers;
