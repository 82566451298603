import { formatPayload } from "../authentication/Utils";
import * as moment from 'moment';
import ReactGA from 'react-ga4';
const serverURL = process.env.REACT_APP_SERVER_URL;
const serverPort = process.env.REACT_APP_SERVER_PORT;

export async function RetrieveMyReports() {
  const response = await fetch(serverURL+serverPort+"/myincidents", {
    method: "GET",
    mode: "cors",
    credentials: "include"
  })
    .catch(function(error) {
      console.log("Looks like there was a problem getting my reports: \n", error);
      return null;
    })
    .then(data => {
      if (data !== null){
        return data.json();
      }
      else return null;
    });
  return response;
}

export async function RetrieveMyProtoReports() {
  const response = await fetch(serverURL+serverPort+"/myprotoreports", {
    method: "GET",
    mode: "cors",
    credentials: "include"
  })
    .catch(function(error) {
      console.log("Looks like there was a problem getting my protoreports: \n", error);
      return null;
    })
    .then(data => {
      if (data !== null){
        return data.json();
      }
      else return null;
    });
  return response;
}

export async function RetrieveCurrentReports() {
  var cutoff=moment();
  var severity=localStorage.getItem('showReportSeverity');
  var within=parseInt(localStorage.getItem('showReportsWithin'));

  if(severity===null || severity==="") {
    severity="all";
  };
  if(isNaN(within)|| within<1 || within>5) {
    within=3;
  };

  switch(within) {
    case 1:
      cutoff=moment().subtract(15, "minutes");
      break;
    case 2:
      cutoff=moment().subtract(30, "minutes");
      break;
    case 3:
      cutoff=moment().subtract(1, "hours");
      break;
    case 4:
      cutoff=moment().subtract(5, "hours");
      break;
    case 5:
      cutoff=moment().subtract(1, "days");
      break;
    default:
      cutoff=moment().subtract(1, "days");
  };

  const response = await fetch(serverURL+serverPort+"/incidents/"+severity+"/"+cutoff.format('X'), {
    method: "GET",
    mode: "cors",
    credentials: "include"
  })
    .catch(function(error) {
      console.log("Looks like there was a problem getting current reports: \n", error);
      return null;
    })
    .then(data => {
      if (data !== null){
        return data.json();
      }
      else return null;
    });
  return response;
}
  
export async function RegisterProtoReport(latitude, longitude, address) {
  const formBody = formatPayload({
    longitude: longitude,
    latitude: latitude,
    address: address
  });
  const response = await fetch(serverURL+serverPort+"/incident/register", {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: formBody
  });
  ReactGA.event({
    category: "Reports",
    action: "Register a proto-Report",
  });
  return response.ok;
}

export async function NewReport(latitude, longitude, report) {
  const formBody = formatPayload({
    longitude: longitude,
    latitude: latitude,
    description: report.description,
    severity: report.severity,
    address: report.address
  });
  const response = await fetch(serverURL+serverPort+"/incident/new", {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: formBody
  });
  ReactGA.event({
    category: "Reports",
    action: "Register a proto-Report",
  });
  return response.ok;
}

export async function CompleteReport(report) {
  const formBody = formatPayload({
    description: report.description,
    severity: report.severity,
    address: report.address
  });
  const response = await fetch(
    serverURL+serverPort+`/incident/report/${report.reportID}`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: formBody
    });
    ReactGA.event({
      category: "Report",
      action: "Complete a Report",
    });
  return response.ok;
}

export async function IgnoreReport(reportID) {
    const response = await fetch(
      serverURL+serverPort+`/incident/ignore/${reportID}`, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    );
    ReactGA.event({
      category: "Reports",
      action: "Ignore a Report",
    });
    return response.ok;
  }
  