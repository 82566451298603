import React, { useState, useEffect } from "react";
import { CompleteReport } from "../data_exchange/Report_Data";
import Severity from "../ui_elements/Severity";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createTheme, useTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import ReactGA from "react-ga4";
import { Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogActions,
  Grid, 
  Typography, 
  TextField } from "@mui/material";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "80%",
    padding: theme.spacing(2, 4, 3),
    backgroundColor: theme.palette.background.paper
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  }
}));

const theme = createTheme({
  palette: {
    text: {
      primary: '#2D2D68',
      secondary: '#3f3f3f',
      disabled: '#d5d5db',
      hint: '#9d9da5'
    },
    typography: {
      body1: {
        fontSize: "1rem",
      },
      body2: {
        fontSize: "0.875rem",
      },
      button:{
        fontSize: "0.875rem",
      }
    }
  }
});
  

export default function UpdateReport(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [state, setState] =useState({
    description: "",
    severity: "",
    address: ""
  });

  useEffect(() => {
    if(props.open){
      ReactGA.send({hitType: "pageview",page: "/edit_report", title:"Update Report"});
    }
  }, []);


  const handleDescriptionChange = e => {
    setState({ ...state, description: e.target.value });
  };

  const handleAddressChange = e => {
    setState({ ...state, address: e.target.value });
  };

  function handleSeverityChange (newSeverity) {
    setState({ ...state, severity: newSeverity });
  };
  
  const cancelForm=() => {
    setState({ ...state, description: "",severity: "", address: "" });
    props.parentCallback(); 
  }

  const updateReport = async () => {
    const updatedReport={
      description: state.description===""?props.report.description:state.description,
      severity: state.severity===""?props.report.severity:state.severity,
      address: state.address===""?props.report.address:state.address,
      reportID: props.report.reportID
    }
    // Update the database
    await CompleteReport(updatedReport);
    setState({ ...state, description: "",severity: "", address:"" });

    // Close the form and let the parent know
    props.parentCallback(); 
  }

  if(typeof props.report==="undefined") {
    return (<div>No reported incidents</div>);
  } else if (!props.report.reportID) {
    return(<div>Loading..</div>);
  } else {
    return (
      <ThemeProvider theme={theme}>
      <Dialog
          fullScreen={fullScreen}
          aria-labelledby="update-reports-dialog"
          aria-describedby="allows a user to edit or complete a reported incident"
          open={props.open}
          onClose={cancelForm}
          scroll="paper"
          closeAfterTransition
      >
          {/*<DialogTitle id="edit-report">Edit report</DialogTitle>*/}
          <DialogContent>
              <Grid container spacing={3}>
                  <Grid item xs={12} >
                    <img src={"https://maps.googleapis.com/maps/api/staticmap?center="
                      +props.report.position.lat+","+props.report.position.lng
                      +"&zoom=15&size=350x150&maptype=roadmap&markers=color:red%7C"
                      +props.report.position.lat+","+props.report.position.lng
                      +"&key="+process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                      alt="Map showing the location of the reported incident"
                    ></img>
                  </Grid>
                  <Grid item xs={12}>
                      <Typography className={classes.title} variant="h6" color="textPrimary" gutterBottom>
                          Report
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                          Warn others about areas you feel are unsafe
                      </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField 
                      id="description" 
                      label="Title (summarize the situation)" 
                      variant="outlined" 
                      placeholder="e.g Fighting" 
                      defaultValue={props.report.description} 
                      fullWidth 
                      multiline
                      rows="2" 
                      onChange={handleDescriptionChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField 
                      id="address" 
                      label="Location (where were you)" 
                      variant="outlined" 
                      defaultValue={props.report.address} 
                      fullWidth 
                      multiline
                      rows="2" 
                      onChange={handleAddressChange}
                    />
                  </Grid>
                  <Grid item xs={12} >
                    <Typography variant="body2" color="textSecondary">
                      How would you rate the severity of this incident?
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                      <ButtonGroup
                          orientation="vertical"
                          aria-label="severity button group"
                          fullWidth
                      >
                          <Button 
                            id="sev_low" 
                            variant={(state.severity==="low"||(state.severity===""&&props.report.severity==="low"))?"contained":"outlined"} 
                            startIcon={<Severity severity="low" />}
                            onClick={() => handleSeverityChange("low")}
                            fullWidth
                          >
                            <Grid container spacing={3} >
                              <Grid item xs={3}>
                                <Typography display="inline">Low</Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <Typography variant="body1" color="textSecondary" display="inline" align="right">(be aware)</Typography>
                              </Grid>
                            </Grid>
                          </Button>
                          <Button 
                            id="sev_medium" 
                            variant={(state.severity==="medium"||(state.severity===""&&props.report.severity==="medium"))?"contained":"outlined"} 
                            startIcon={<Severity severity="medium" />}
                            onClick={() => handleSeverityChange("medium")}
                            fullWidth
                          >
                            <Grid container spacing={3} >
                              <Grid item xs={3}>
                                <Typography display="inline">Medium</Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <Typography variant="body1" color="textSecondary" display="inline" align="right">(avoid if possible)</Typography>
                              </Grid>
                            </Grid>
                          </Button>
                          <Button 
                            id="sev_high" 
                            variant={(state.severity==="high"||(state.severity===""&&props.report.severity==="high"))?"contained":"outlined"} 
                            startIcon={<Severity severity="high" />}
                            onClick={() => handleSeverityChange("high")}
                            fullWidth
                          >
                            <Grid container spacing={3} >
                              <Grid item xs={3}>
                                <Typography display="inline">High</Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <Typography variant="body1" color="textSecondary" display="inline" align="right">(avoid completely)</Typography>
                              </Grid>
                            </Grid>
                          </Button>
                      </ButtonGroup>
                  </Grid>
              </Grid>
          </DialogContent>
          <DialogActions>
              <Button onClick={cancelForm} color="secondary">
                  Cancel
              </Button>
              <Button variant="contained" onClick={updateReport} color="primary">
                  Update Report
              </Button>
          </DialogActions>
      </Dialog>
      </ThemeProvider>
    );
  };
}