import React, { useState, useContext, useEffect } from "react";
import "../App.css";
import { Map, InfoWindow, GoogleApiWrapper } from "google-maps-react";
import MarkerList from "./MarkerList";
import { Next_Bus_Info } from "./data_exchange/Bus_Info";
import { UserContext, FriendContext, ReportContext, ProtoReportContext, SensorContext } from "./MarkerContext";
import CentreIcon from "../media/MyWay__centre_icon.svg"; 
import UserIcon from "./ui_elements/UserIcon";
import Severity from "./ui_elements/Severity";
import {PrepareProps} from "./Directions";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {howLongAgo} from "../components/authentication/Utils";
import ReactGA from 'react-ga4';
import * as moment from "moment";

var mapProp;
var googleProp;

function MapContainer(props) {
  //local state for keeping track of active markers and showing info window
  const initialState = {
    activeMarker: null,
    selectedPlace: null,
    showingInfoWindow: false,
    text: null,
    address:null
  }

  // activeMarker if same don't redraw state change

  const [selected, setSelected] = useState(initialState);

  const [user, updateUser] = useContext(UserContext);
  const [friends, setFriends] = useContext(FriendContext);
  const [reports, setReports] = useContext(ReportContext);
  const [protoReports, setProtoReports] = useContext(ProtoReportContext);
  const [sensors, setSensors] = useContext(SensorContext);


  //handle marker clicks and retrieving their data (also bus stop data)
  async function onMarkerClick(props, marker){
    if (selected.showingInfoWindow && marker.id === selected.activeMarker.id) {
      return null
    }
    
    if (marker.clickable) {
      //waiting for better performance to pan to selected marker
      // await mapProp.panTo(marker.position);
      // if (marker.type === "bus") {
      //   console.log(marker.id);
      //   const nextBusses = await Next_Bus_Info(marker.id);
      //   setSelected({
      //     activeMarker: marker,
      //     selectedPlace: props,
      //     showingInfoWindow: true,
      //     text: nextBusses
      //   });
      // }
      if (marker.type === "friend") {
        ReactGA.event({
          category: "Marker",
          action: "See friend data",
        });
        let friend = friends[marker.id];
        var friendText;
        try{
          //friendText = howLongAgo(friend.lastUpdated);
          friendText=moment(friend.lastUpdated).format("HH:mm");
        }
        catch{
          friendText = "Unkown";
        }
        setSelected({
          activeMarker: marker,
          selectedPlace: props,
          showingInfoWindow: true,
          text: friendText
        });
      }
      if (marker.type === "report") {
        let report = reports[marker.id];
        const reportText = report.severity;
        const address = report.address;
        setSelected({
          activeMarker: marker,
          selectedPlace: props,
          showingInfoWindow: true,
          text: reportText,
          address: address
        });
        ReactGA.event({
          category: "Marker",
          action: "See report data",
        });
      }
      if (marker.type === "protoReport") {
        ReactGA.event({
          category: "Marker",
          action: "See proto-report data",
        });
        let protoReport = protoReports[marker.id];
        const protoReportText = protoReport.timestamp;
        const address = protoReport.address;
        setSelected({
          activeMarker: marker,
          selectedPlace: props,
          showingInfoWindow: true,
          text: protoReportText,
          address: address
        });
      }
      if (marker.type === "sensor") {
        ReactGA.event({
          category: "Marker",
          action: "See sensor data",
        });
        let sensor = sensors[marker.id];
        const sensorText=sensor.timestamp;
        setSelected({
          activeMarker: marker,
          selectedPlace: props,
          showingInfoWindow: true,
          text: sensorText
        });
      }
    }
  };

  function onInfoWindowClose(){
    // setSelected(initialState)
    setSelected({
      activeMarker: null,
      showingInfoWindow: false
    });
  };

  function onMapClicked(){
    if (selected.showingInfoWindow)
      // setSelected(initialState);
      setSelected({
        activeMarker: null,
        showingInfoWindow: false
      });
  };

  //center location when map is loaded
  async function mapLoaded(props, map){
    //set a global prop
    mapProp = map;
    googleProp = props.google;
    
    navigator.geolocation.getCurrentPosition(pos => {
      const coords = {
        lat: pos.coords.latitude,
        lng: pos.coords.longitude
      }
      let userNew = user;
      userNew.position = coords;
      updateUser(userNew);
      mapProp.panTo(coords);
    });
  };

  function centerMap(){
    if(mapProp !== undefined){
      mapProp.panTo(user.position);
    }
  }

  //function to format the bus times API response
  const FormatInfoWindow = () => {
    if (!selected.showingInfoWindow) return null;
    let type = selected.activeMarker.type;
    if (type === "bus") {
      if (selected.text == null) {
        return <h1>Information unavailable</h1>;
      }
      return (
        <>
          <h1>{selected.activeMarker.name}</h1>
          {selected.text.map((nextBus, i) => (
            <li justifyContent="between" key={i}>
              <span>{nextBus.BusRoute} - </span>
              <span>{nextBus.Minutes}</span>
            </li>
          ))}
        </>
      );
    } else if (type==="report" || type==="protoReport") {
      return (
        <div>
        <Severity severity={selected.text} /> - {selected.text}
        <Typography variant="body2">
          {selected.address}
        </Typography>
        <Typography variant="subtitle1" color="textPrimary">
            {selected.activeMarker.name}
          </Typography>
        </div>
      )
    } else if (type==="sensor") {
      return (
        <div>
          <Typography variant="subtitle1" color="textPrimary">
            {selected.activeMarker.name}
          </Typography>
          <Typography variant="body2" >
            {selected.text}
          </Typography>
        </div>
      )
    } else {
      return (
        <>
          <Typography variant="body2" color="textPrimary">
            {selected.activeMarker.name}
          </Typography>
          <Typography variant="subtitle1" >
            Last seen: {selected.text}
          </Typography>
        </>
      );
    }
  };

  
  if (!props.loaded) return <div>Loading...</div>;
  else
    return (
      <Map
        google={props.google}
        onClick={onMapClicked}
        styles={props.mapStyles}
        initialCenter={{ lat: 51.455557, lng: -0.966805 }}
        zoom={16}
        onReady={mapLoaded}
        gestureHandling= {"greedy"}
        disableDefaultUI={true}
      >
        <PrepareProps google={googleProp} map={mapProp}></PrepareProps>
        <UserIcon map={mapProp}/>
        
        <MarkerList 
        click={onMarkerClick} 
        newReport={props.newReport} forceRefreshFriends={props.forceRefreshFriends} menuClosed={props.menuClosed} parentCallback={props.parentCallback}/>
        <InfoWindow
          marker={selected.activeMarker}
          onClose={onInfoWindowClose}
          visible={selected.showingInfoWindow}
        >
          <FormatInfoWindow />
        </InfoWindow>
        <IconButton 
          className="map-button"
          aria-label="center_map"
          onClick={centerMap}>
            <img src={CentreIcon} width="60rem" style={{left:0}}/>
        </IconButton>
      </Map>
    );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
})(MapContainer);


MapContainer.defaultProps = {
mapStyles:[
    {
      "featureType": "landscape",
      "stylers": [
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.attraction",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.business",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.place_of_worship",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.school",
      "stylers": [
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "simplified"
        }
      ]
    }
  ]}
