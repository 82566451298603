import { createTheme } from '@mui/material/styles';

export default createTheme({
    root: {
        spacing: 1,
    },
    palette: {
        text: {
            primary: '#2D2D68',
            secondary: '#3f3f3f',
            disabled: '#d5d5db',
            hint: '#00c7ac',
        },
        primary: {
            main: '#00c7ac',
        },
        secondary: {
            main: '#d6003d',
        },
        background: {
            paper: '#fff',
            default: '#f4f4f6',
        }
    },
    typography: {
        fontFamily: [
            'Avenir',
            'Roboto',
            'Arial',
            'sans-serif',
        ].join(','),
        subtitle1: {
            fontSize: "1.2rem",
            fontWeight: 400,
        },
        subtitle2: {
            fontSize: "1rem",
            fontWeight: 500,
        },
        body1: {
            fontSize: "0.875rem",
            fontWeight: 500,
        },
        body2: {
            fontSize: "0.8rem",
            fontWeight: 400,
        },
        button:{
            fontSize: "0.875rem",
        },
    },
    bigButton:{
        background: '#00c7ac',
        color: 'white',
    },
    checkbox:{
        color:{
            primary: "#00c7ac"
        }
    }
});