import {getMessaging, getToken} from 'firebase/messaging';
import {initializeApp} from 'firebase/app';

export const initializeFirebase = () => {
    initializeApp({
        apiKey: "AIzaSyC5xIypiIJwZcExJxyzqyxkMPGMZW0S53I",
        authDomain: "myway-dcca6.firebaseapp.com",
        projectId: "myway-dcca6",
        storageBucket: "myway-dcca6.appspot.com",
        messagingSenderId: "164218210277",
        appId: "1:164218210277:web:f83fda95b0314cdf9f910c"
        });
  }  

  export const askForPermissionToReceivePushNotifications = async () => {
    try {
      const messaging = await getMessaging();
  
      const token = await getToken(messaging, {vapidKey: "BBQOZuxNVKvuJ084OgKS6Spm7y6RzJHNPL0QYTvyM0APMi8edCXOXdOHDv0a3V5-n0w2xaHo8qKRyQ4BhEUUScM"});
      console.log('user token: ', token);
  
      return token;
    } catch (error) {
      console.error(error);
    }
  }