import React, {useState, useEffect } from "react";
import LocationOnIcon from "../../media/MyWay__eye_open_icon_dark.svg";
import LocationOffIcon from "../../media/MyWay__eye_closed_icon_light.svg";
import Icon from '@material-ui/core/Icon';

 export default function FriendLocationShare(props) {
  var [location, setLocation] = useState(props.locationShared);

  useEffect(() => {
    setLocation(props.locationShared);
  }, [props])

  return (
      <Icon>
      <img src={location ? LocationOnIcon : LocationOffIcon} width="10rem" height="10rem"/>
      </Icon>
  );
}
