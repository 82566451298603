import React, { useState, useContext, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import themeSignUpIn from "../themeSignUpIn";
import { withSnackbar } from "notistack";
import ReactGA from "react-ga4";

var snackBar = null;

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  whiteText: {
    color: "#ffffff",
  },
  whiteButton: {
    color: "#ffffff",
    margin: theme.spacing(1, 0, 3),
  },
}));

function PrivacyPolicy(props) {
  snackBar = props.enqueueSnackbar;
  ReactGA.send({hitType: "pageview",page: "/privacy_policy", title:"Privacy Policy"});

  const history = useHistory();
  const classes = useStyles();

  return (
    <ThemeProvider theme={themeSignUpIn}>
      <Container
        component="main"
        maxWidth="xs"
        className="container"
        style={{
          background: `linear-gradient(rgba(00,199,172,1),transparent)`,
          backgroundColor: "#2d2d68",
          maxHeight: "window.innerHeight",
        }}
      >
        <Grid
          container
          alignItems="left"
          style={{ height: "90vh", overflow: "auto", marginTop: "10vh" }}
        >
          <Grid item xs={12} align="left">
            <Typography variant="body1" color="textPrimary">
              [<b>TLDR:</b>We store your name, phone number, and if you give us
              permission we store your current location and home address. We use
              your data in the app, and to contact you about the app. We do not
              sell or share your data with any other organisation.]
              <br />
            </Typography>
            <Typography
              variant="h5"
              color="textPrimary"
              align="center"
              style={{ marginTop: "3vh" }}
            >
              Thingitude Privacy Policy
            </Typography>
            <Typography color="textPrimary" align="center">
              15 October 2020
            </Typography>
            <Typography variant="h6" color="textPrimary" align="center">
              Thingitude's Data Protection Officer{" "}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              Mark Stanley, 10 Shenstone Road, Reading RG2 0DT.
            </Typography>
            <Typography variant="body1" color="textPrimary">
              Phone - 07771 870731
            </Typography>
            <Typography variant="body1" color="textPrimary">
              Email - mark.stanley@thingitude.com
            </Typography>
            <Typography variant="h6" color="textPrimary" align="center" style={{ marginTop: "3vh" }}>
              Personal data we hold, and how we get it{" "}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              <ul>
                <li>
                  User’s name, phone number and email - You will have provided
                  your contact information during the sign-up process.
                </li>
                <li>
                  User’s “home address” - if the user has set it in the app.
                </li>
                <li>
                  The user ID of other users the user has identified as Friends
                  in the MyWay app
                </li>
                <li>
                  The most recent GPS coordinates of the user’s phone - whenever
                  the user gives permission to share their location in the MyWay
                  app. If the user does not give permission we do not store the
                  coordinates.
                </li>
              </ul>
            </Typography>
            <Typography variant="h6" color="textPrimary" align="center" style={{ marginTop: "3vh" }}>
              How we use your data{" "}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              <ul>
                <li>
                  We use your name and contact details so we can contact you for
                  feedback on the MyWay app and provide support and maintenance.
                </li>
                <li>
                  We use your “home address” (if you have set it in the app) so
                  we can show you the route home at the push of a button, and
                  automatically notify those Friends you choose to
                  notify when you complete your journey home.
                </li>
                <li>
                  We use the user ID of users you identified as Friends in the
                  MyWay app so you can see them (if the Friend allows) and the
                  Friend can see your whereabouts (if you let them) on the app.
                </li>
               <li>
                  We use your phone’s GPS coordinates so we can show you and
                  your Friends (if you share your location in the app) where you
                  are on the map.
                </li>
              </ul>
            </Typography>
            <Typography variant="h6" color="textPrimary" align="center" style={{ marginTop: "3vh" }}>
              Sharing your personal data with other people or organisations{" "}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              We will not share your data with any people other than those
              Friends you choose to allow in the app.
            </Typography>
            <Typography variant="body1" color="textPrimary">
              We will not sell or share your data with any other organisation.
            </Typography>
            <Typography variant="h6" color="textPrimary" align="center" style={{ marginTop: "3vh" }}>
              How we keep your data secure{" "}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              Your data will be stored on our database, which runs on a server
              hosted in an AWS data centre located within the UK.
            </Typography>
            <Typography variant="body1" color="textPrimary">
              The database is protected by a username and password, which is
              only known to the Data Protection Officer and the support team.
            </Typography>
            <Typography variant="h6" color="textPrimary" align="center" style={{ marginTop: "3vh" }}>
              How long we keep your data{" "}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              With your consent we will keep your data for as long as you remain
              a user of the MyWay app, plus 6 months in case you change your
              mind or need to see your data.
            </Typography>
            <Typography variant="h6" color="textPrimary" align="center" style={{ marginTop: "3vh" }}>
              If you want to access your data or instruct us to delete your data{" "}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              You are entitled to know and see the data we hold about you. We
              will be happy to provide it to you upon request. Please contact
              the Data Protection Officer with your request. Please note that
              you are required to provide proof of identification and address.
            </Typography>
            <Typography variant="body1" color="textPrimary">
              Upon request we can provide a copy of the data we hold about you
              for you to keep, forward or transfer to another service provider.
              Data will be provided electronically in a password protected file
              using a common (non-proprietary) format. Please contact the Data
              Protection Officer with your request. Please note that you are
              required to provide proof of identification and address.
            </Typography>
            <Typography variant="body1" color="textPrimary">
              If you no longer want us to hold data about you, we will delete
              those that we do not require for administrative or statutory
              purposes. Before deleting your data we can, upon request provide a
              digital copy that you may keep or transfer to another service
              provider. Please contact the Data Protection Officer with your
              request. Please note that you are required to provide proof of
              identification and address. We will contact you one last time in
              writing (letter or email) to confirm that your data has been
              deleted.
            </Typography>

            <Button
              fullWidth
              href="/signup"
              variant="outlined"
              color="primary"
              className={classes.whiteButton}
            >
              Back to Sign Up
            </Button>
            <Button
              fullWidth
              href="/signin"
              variant="outlined"
              color="primary"
              className={classes.whiteButton}
            >
              Back to Sign In
            </Button>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

export default withSnackbar(PrivacyPolicy);
