import React, { useState, createContext } from "react";
import user_icon from "../media/MyWay__location_icon.svg";

//using a user context
export const UserContext = createContext([{}, function() {}]);
export const UserContextProvider = props => {
  const [user, updateUser] = useState(
    {
       name: "ME",
       position: { lat: 51.455557, lng: -0.966805 },
       symbol: user_icon,
       clickable: false,
       showLocation: true,
       provider: "local"
    }
  );
  return (
    <UserContext.Provider value={[user, updateUser]}>
      {props.children}
    </UserContext.Provider>
  );
};


export const BusContext = createContext([{}, function() {}]);
export const BusContextProvider = props => {
  const [buses, setBuses] = useState([
    {
      name: "Thingitude Office",
      position: { lat: 51.455557, lng: -0.966805 },
      symbol: "logo",
      id: 0,
      clickable: false,
      type: "bus",
      lastUpdated: 0
    },
    {
      name: "Office",
      position: { lat: 51.45554, lng: -0.966805 },
      symbol: "logo",
      id: 2,
      clickable: false,
      type: "bus",
      lastUpdated: 0
    }
  ]);

  return (
    <BusContext.Provider value={[buses, setBuses]}>
      {props.children}
    </BusContext.Provider>
  );
};
export default BusContextProvider;


export const FriendContext = createContext([{}, function() {}]);
export const FriendContextProvider = props => {
  const [friends, setFriends] = useState([
    // {
    //   name: "TEMP_FRIEND",
    //   position: { lat: 51, lng: 0.9 },
    //   symbol: "friend_icon",
    //   userID: 3,
    //   clickable: true,
    //   type: "friend",
    //   lastUpdated: new Date().toUTCString(),
    //   visible: true,
    //   locationShared: true
    // }
  ]);

  return (
    <FriendContext.Provider value={[friends, setFriends]}>
      {props.children}
    </FriendContext.Provider>
  );
};


//using a Report context
export const ReportContext = createContext([{}, function() {}]);
export const ReportContextProvider = props => {
  const [reports, setReports] = useState([
  ]);

  return (
    <ReportContext.Provider value={[reports, setReports]}>
      {props.children}
    </ReportContext.Provider>
  );
};

//using a ProtoReport context
export const ProtoReportContext = createContext([{}, function() {}]);
export const ProtoReportContextProvider = props => {
  const [protoReports, setProtoReports] = useState([

  ]);

  return (
    <ProtoReportContext.Provider value={[protoReports, setProtoReports]}>
      {props.children}
    </ProtoReportContext.Provider>
  );
};


export const JourneyContext = createContext([{}, function() {}]);
export const JourneyContextProvider = props => {
  const [journey, updateJourney] = useState([]);

  return (
    <JourneyContext.Provider value={[journey, updateJourney]}>
      {props.children}
    </JourneyContext.Provider>
  );
};

//using a Sensor context
export const SensorContext = createContext([{}, function() {}]);
export const SensorContextProvider = props => {
  const [sensors, setSensors] = useState([]);

  return (
    <SensorContext.Provider value={[sensors, setSensors]}>
      {props.children}
    </SensorContext.Provider>
  );
};


export const PlaceContext = createContext([{}, function() {}]);
export const PlaceContextProvider = props => {
  const [places, updatePlaces] = useState([
    // {
    //   name: "Home",
    //   location: "The Blade, Abbey Square, Reading RG1 3BE, United Kingdom",
    // }
  ]);

  return (
    <PlaceContext.Provider value={[places, updatePlaces]}>
      {props.children}
    </PlaceContext.Provider>
  );
};