/*global google*/
import React, { useContext, useState, useEffect } from "react";
import {
  BusContext,
  FriendContext,
  UserContext,
  ReportContext,
  ProtoReportContext,
  SensorContext,
} from "./MarkerContext";
import { Marker } from "google-maps-react";
import {
  RetrieveCurrentReports,
  RetrieveMyProtoReports,
  RegisterProtoReport,
} from "./data_exchange/Report_Data";
import { RetrieveRelevantSensorData } from "./data_exchange/Sensor_Data";
import { ParseBool } from "./authentication/Utils";
import pin_high_icon from "../media/MyWay__pin_high_icon.svg";
import pin_medium_icon from "../media/MyWay__pin_medium_icon.svg";
import pin_low_icon from "../media/MyWay__pin_low_icon.svg";
import pin_proto_icon from "../media/MyWay__pin_proto_icon.svg";
import streetlight_icon from "../media/MyWay__streetlight_icon.svg";
import people_icon from "../media/MyWay__people_icon.svg";
import people_icon_med from "../media/MyWay__people_icon_med.svg";
import people_icon_old from "../media/MyWay__people_icon_old.svg";
import people_light_icon from "../media/MyWay__people_light_icon.svg";
import * as moment from "moment";
import { withSnackbar } from "notistack";
import Bus_Stop_Info from "./data_exchange/Bus_Info";
import bus_stop_icon from "../media/MyWay__bus_icon_dark.svg";
import BusMarkers from "../components/ui_elements/BusMarkers";
import { howLongAgo } from "../components/authentication/Utils";

var snackBar = null;

//component for holding the Markers and later embedding them on the map
function MarkerList(props) {
  snackBar = props.enqueueSnackbar;
  const [user, updateUser] = useContext(UserContext);
  const [buses, setBuses] = useContext(BusContext);
  const [friends, setFriends] = useContext(FriendContext);
  const [reports, setReports] = useContext(ReportContext);
  const [protoReports, setProtoReports] = useContext(ProtoReportContext);
  const [sensors, setSensors] = useContext(SensorContext);
  //the props that every marker needs
  const googleProp = props.google;
  const mapProp = props.map;
  const mapCenterProp = props.mapCenter;
  const [forceRender, toggleForceRender] = useState(false); //dirty tricks!

  useEffect(() => {
    try {
      // loadBusStops();
      reloadMarkers();
      // Set a timer to refresh reports every minute
      const interval = setInterval(() => {
        reloadMarkers();
      }, 60000);
      return () => clearInterval(interval);
    } catch {
      snackBar("Couldn't contact server", { variant: "error" });
    }
  }, [props.menuClosed]); // it seems you need to pass in props.menuClosed for it to notice the change


  // friend refresh
  useEffect(() => {
    try {
      const interval = setInterval(() => {
        // We only refresh friends if the SlideMenu is closed and the user is sharing their location
        if (props.menuClosed && user.showLocation) {
          props.forceRefreshFriends();
          toggleForceRender(!forceRender);
        }
      }, 15000);
      return () => clearInterval(interval);
    } catch {
      snackBar("Couldn't refresh friends", { variant: "error" });
    }
  }, [props.menuClosed]);

  useEffect(() => {
    try {
      const interval = setInterval(() => {
        if (props.newReport) {
          loadProtoReports();
          setTimeout(() => {
            props.parentCallback(false);
            toggleForceRender(!forceRender);
          }, 800);
        }
      }, 10000);
      return () => clearInterval(interval);
    } catch {
      snackBar("Couldn't swiftly refresh reports", { variant: "error" });
    }
  }, [props.newReport]);

  async function reloadMarkers() {
    // We only refresh markers if the SlideMenu is closed - which sets props.menuClosed to true
    if (props.menuClosed) {
      if (localStorage.getItem("showReports") === "true") {
        await loadCurrentReports();
      } else {
        setReports([]);
      }
      if (
        localStorage.getItem("showLighting") === "true" ||
        localStorage.getItem("showPeople") === "true"
      ) {
        await loadSensors();
      } else {
        setSensors([]);
      }
      await loadProtoReports();
      toggleForceRender(!forceRender);
    }
  }

  function getPeopleIconAge(timestamp) {
    var currentTime = new Date();
    var minsAgo = moment(currentTime).diff(timestamp, "minutes");

    if (minsAgo <= 5) {
      return people_icon;
    }
    if (minsAgo <= 10 && minsAgo > 5) {
      return people_icon_med;
    }
    if (minsAgo > 10) {
      return people_icon_old;
    }
  }

  const loadSensors = async () => {
    const fetchSensors = await RetrieveRelevantSensorData(
      localStorage.getItem("showLighting"),
      localStorage.getItem("showPeople")
    );
    const showLighting = ParseBool(localStorage.getItem("showLighting"));
    const showPeople = ParseBool(localStorage.getItem("showPeople"));
    //prevent errors if unretrieved
    if (fetchSensors !== null) {
      //map the returned array to the SensorContext
      var arrayOfSensors = fetchSensors.map(
        (sensor) =>
          (sensor = {
            name:
              (showLighting && sensor.lightlevel > 0
                ? "Light level: " + sensor.lightlevel + " "
                : "") + (showPeople ? "People: " + sensor.peoplecount : ""),
            sensorID: sensor.sensorid,
            position: { lat: sensor.latitude, lng: sensor.longitude },
            symbol:
              sensor.lightlevel > 0 && sensor.peoplecount > 0
                ? people_light_icon
                : sensor.lightlevel > 0
                ? streetlight_icon
                : getPeopleIconAge(sensor.timestamp),
            timestamp: howLongAgo(sensor.timestamp),
            clickable: true,
            label:
              sensor.peoplecount > 0
                ? {
                    color: "#3f3f3f",
                    fontSize: "12px",
                    fontWeight: "600",
                    text: " " + sensor.peoplecount,
                  }
                : "",
            type: "sensor",
          })
      );
      setSensors(arrayOfSensors);
    } else {
      setSensors([]);
    }
  };

  const loadCurrentReports = async () => {
    if (localStorage.getItem("showReports") === "true") {
      const fetchReports = await RetrieveCurrentReports();
      //prevent errors if unretrieved
      if (fetchReports !== null) {
        //map the returned array to the friend context
        var arrayOfReports = fetchReports.map(
          (report) =>
            (report = {
              name: howLongAgo(report.timestamp) + " - " + report.description,
              reportID: report.incidentid,
              description: report.description,
              severity: report.severity,
              status: report.status,
              position: { lat: report.latitude, lng: report.longitude },
              symbol:
                report.severity === "high"
                  ? pin_high_icon
                  : report.severity === "medium"
                  ? pin_medium_icon
                  : pin_low_icon,
              address: report.address,
              timestamp: howLongAgo(report.timestamp),
              lastUpdated: new Date().toUTCString(),
              clickable: true,
              type: "report",
            })
        );
        setReports(arrayOfReports);
      }
    } else {
      setReports([]);
    }
  };

  const loadProtoReports = async () => {
    const myProtoReports = await RetrieveMyProtoReports();
    if (myProtoReports !== null) {
      var arrayOfProtoReports = myProtoReports.map(
        (protoReport) =>
          (protoReport = {
            name:
              "Logged " +
              moment(protoReport.timestamp).format("D MMM HH:mm") +
              " - by you",
            reportID: protoReport.incidentid,
            description: protoReport.description,
            severity: protoReport.severity,
            status: protoReport.status,
            position: { lat: protoReport.latitude, lng: protoReport.longitude },
            symbol: pin_proto_icon,
            address: protoReport.address,
            timestamp: howLongAgo(protoReport.timestamp),
            lastUpdated: new Date().toUTCString(),
            clickable: true,
            type: "protoReport",
          })
      );
      await setProtoReports(arrayOfProtoReports);
    }
  };

  //loads new bus stop markers from BUS API to the context
  const loadBusStops = async () => {
    const fetchBusStops = await Bus_Stop_Info();
    //prevent errors if unretrieved
    if (fetchBusStops !== undefined) {
      var arrayOfBusStops = fetchBusStops.map(
        (stop) =>
          (stop = {
            name: stop.Description,
            position: { lat: stop.Latitude, lng: stop.Longitude },
            symbol: bus_stop_icon,
            key: buses.length + 1,
            id: stop.NAPTAN,
            clickable: true,
            onClick: props.onClick,
            type: "bus",
            lastUpdated: new Date().toUTCString(),
          })
      );
      setBuses(arrayOfBusStops);
    }
  };

  //returns a component with Marker components nested in it,
  //that are instantiated from the marker context
  return (
    <div className="MarkerList">
      {/* <BusMarkers google={googleProp} map={mapProp} mapCenter={mapCenterProp}/> */}
      {sensors.map((sensor, i) => (
        <Marker
          key={"sensor-" + i}
          id={i}
          name={sensor.name}
          position={sensor.position}
          map={mapProp}
          google={googleProp}
          mapCenter={mapCenterProp}
          icon={{
            url: sensor.symbol,
            anchor: new google.maps.Point(16, 16),
            scaledSize: new google.maps.Size(32, 32),
            labelOrigin:
              sensor.symbol === people_icon ||
              sensor.symbol === people_icon_med ||
              sensor.symbol === people_icon_old
                ? new google.maps.Point(24, 16)
                : new google.maps.Point(12, 16),
          }}
          label={sensor.label}
          onClick={props.click}
          clickable={sensor.clickable}
          type={sensor.type}
          timestamp={sensor.timestamp}
        />
      ))}
      {reports.map((report, i) => (
        <Marker
          key={"report-" + i}
          id={i}
          name={report.name}
          position={report.position}
          map={mapProp}
          google={googleProp}
          mapCenter={mapCenterProp}
          icon={{
            url: report.symbol,
            anchor: new google.maps.Point(16, 16),
            scaledSize: new google.maps.Size(32, 32),
          }}
          onClick={props.click}
          clickable={report.clickable}
          type={report.type}
          timestamp={report.timestamp}
        />
      ))}
      {protoReports.map((protoReport, i) => (
        <Marker
          key={"proto-" + i}
          id={i}
          name={protoReport.name}
          position={protoReport.position}
          map={mapProp}
          google={googleProp}
          mapCenter={mapCenterProp}
          icon={{
            url: protoReport.symbol,
            anchor: new google.maps.Point(16, 16),
            scaledSize: new google.maps.Size(32, 32),
          }}
          onClick={props.click}
          clickable={protoReport.clickable}
          type={protoReport.type}
          timestamp={protoReport.timestamp}
        />
      ))}
      {friends.map((friend, i) =>
        friend.visible ? (
          <Marker
            key={i}
            id={i}
            name={friend.name}
            position={friend.position}
            map={mapProp}
            google={googleProp}
            mapCenter={mapCenterProp}
            icon={{
              url: friend.symbol,
              anchor: new google.maps.Point(16, 16),
              scaledSize: new google.maps.Size(32, 32),
            }}
            onClick={props.click}
            clickable={friend.clickable}
            type={friend.type}
            // lastUpdated={howLongAgo(friend.lastseen)}
          />
        ) : null
      )}
    </div>
  );
}

export default withSnackbar(MarkerList);
