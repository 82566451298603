import React from "react";
import Button from "@material-ui/core/Button";
import BusIcon from "../../media/MyWay__bus_icon_dark copy.svg";
import BusDarkIcon from "../../media/MyWay__bus_icon_dark.svg";
import WalkIcon from "../../media/MyWay__walk_icon_light.svg";
import WalkDarkIcon from "../../media/MyWay__walk_icon_dark.svg";
import { Grid, Typography, Paper } from "@material-ui/core";
import ReactGA from 'react-ga4';

export default function Transport(props) {
  var bus = props.bus;
  // var directionsRendererPassive = props.directionsRenderer
  // var walkDirections = props.walkDirections
  // var busDirections = props.busDirections
  const setBusUse = props.setBusUse;
  var durationWalk = props.durationWalk;
  var durationBus = props.durationBus;

  function toggleOption(which) {
    if (bus & (which === 0)) {
      setBusUse(false);
      ReactGA.event({
        category: "Directions",
        action: "Navigate by walking",
      });
      // directionsRendererPassive.prototype.setDirections(walkDirections);
    }
    if (!bus & (which === 1)) {
      setBusUse(true);
      ReactGA.event({
        category: "Directions",
        action: "Navigate by bus",
      });
      // directionsRendererPassive.prototype.setDirections(busDirections);
    }
  }

  return (
    <Grid
      container
      direction="row"
      spacing={0}
    >
      <Grid item xs={5}>
        <Button
          size="small"
          variant="outlined"
          color={bus ? "" : "primary" }
          aria-label="locationIcon"
          onClick={() => toggleOption(0)}
        >
          <img
            src={bus ? WalkIcon : WalkDarkIcon}
            width="20rem"
            height="20rem"
            alt="Walk icon"
            style={{paddingRight: 3}}
          />
          {durationWalk}
        </Button>
      </Grid>
      <Grid item xs={5}>
        <Button
          size="small"
          variant="outlined"
          color={bus ? "primary" : "" }
          aria-label="locationIcon"
          onClick={() => toggleOption(1)}
        >
          <img
            src={bus ? BusDarkIcon : BusIcon}
            width="20rem"
            height="20rem"
            alt="Bus icon"
            style={{paddingRight: 3}}
          />
          {durationBus}
        </Button>
      </Grid>
    </Grid>
    );
}
