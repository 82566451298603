import React, { useContext, useState, useEffect } from "react";
import { Button, Typography } from "@material-ui/core";
import LocationOnIcon from "../../media/MyWay__location-on2_icon.svg";
import LocationOffIcon from "../../media/MyWay__location-off2_icon.svg"; 
import {UserContext} from "../MarkerContext";
import { LocationPreference } from "../data_exchange/User_Data";
import {ParseBool} from "../authentication/Utils";

import "../../App.css";

 export default function Location(props) {
  const [user, updateUser] = useContext(UserContext);
  var [location, setLocation] = useState(user.showLocation);

  useEffect(() => {
    let position = localStorage.getItem('showLocationByDefault')
    if(position === null){
      setTimeout(() => {
        setLocation(ParseBool(localStorage.getItem('showLocationByDefault')))
      }, 700);
    }
    else{
    setLocation(ParseBool(position))
    }
  }, [user])

  
  function toggleLocation() {
    var userNew = user;
    var update = !location;
    userNew.showLocation = update;
    LocationPreference(update);
    //might want to not enable if server failed
    setLocation(update);
    updateUser(userNew);
    props.forceRefresh();
  }

  return (
    <Button
      size="small" 
      children=""
      aria-label="locationIcon" onClick={toggleLocation}
      endIcon={<img src={location ? LocationOnIcon : LocationOffIcon} height="30rem" alt={location? "I am showing my location" : "Not showing my location"}/>}
    >
    </Button>
  );
}
